import * as React from 'react';
import { useAppContext } from '../../lib/contexts';

/**
 * Describes the current version of the instruction;
 * if the user has already seen the instruction, they
 * will have this value in their local storage so
 * we do not show it again.
 */
export const TUTORIAL_VERSION = 1;

/**
 * The amount of time, in milliseconds,
 * to wait before showing the user the tutorial
 * again.
 *
 * Leading digit describes number of days
 */
export const TUTORIAL_EXPIRE = 2 * 24 * 60 * 60 * 1000;

/** Provides a callback function to acknowledge the current version of the tutorial */
export const useAcknowledgeTutorial = () => {
  const { setAppState } = useAppContext();
  const acknowledge = React.useCallback(() => {
    setAppState((s) => ({
      ...s,
      persisted: {
        ...s.persisted,
        tutorial: {
          version: TUTORIAL_VERSION,
          completedAt: Date.now(),
        },
      },
    }));
  }, [setAppState]);
  return acknowledge;
};

/** Hook for determining if we should show the tutorial */
export const useIsTutorialVisible = () => {
  const { appState } = useAppContext();
  const isVisible = React.useMemo(() => {
    const tutorial = appState.persisted.tutorial;
    return (
      !tutorial ||
      tutorial.version < TUTORIAL_VERSION ||
      tutorial.completedAt + TUTORIAL_EXPIRE < Date.now()
    );
  }, [appState.persisted.tutorial]);

  return isVisible;
};
