import * as Common from 'common';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { generateEndpoint } from '../../../../../../lib/types';
import { Colors } from '../../../../../../styles';

interface Props {
  /** ID for the venue */
  id: Common.Venue['id'];
  /** The name of the venue */
  name: string;
  /** The url to the venue logo */
  image: string;
  /** Anything else to do upon clicking */
  onClick: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

enum Classes {
  Logo = 'logo',
  Name = 'name',
}

export const Entry: React.FC<Props> = (props) => {
  const to = generateEndpoint(props.id);
  return (
    <StyledEntry to={to} onClick={props.onClick}>
      <div className={Classes.Logo}>
        <img src={props.image} alt={props.name} />
      </div>
      <div className={Classes.Name}>{props.name}</div>
    </StyledEntry>
  );
};
Entry.displayName = 'Entry';

const StyledEntry = styled(Link)`
  border-radius: 8px;
  background-color: ${Colors.grayscale02};
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 74px;

  padding: 8px;
  cursor: pointer;

  .${Classes.Logo} {
    width: 76px;
    height: 100%;
    overflow: hidden;
    flex-shrink: 0;

    img {
      width: 76px;
      height: 100%;
      margin-right: 0px;
      object-fit: contain;
    }
  }

  .${Classes.Name} {
    text-align: left;
    margin-left: 8px;
    font-size: 18px;

    color: ${Colors.grayscale05};
    font-weight: 200;
  }
`;
