import * as React from 'react';
import styled from 'styled-components';

import { Colors, Mixins, Typography } from '../../styles';

import KF_LOGO from '../../img/kf_logo_wide.png';

const COPY = {
  HEADLINE: 'About',
  FIRST: {
    LINK: 'http://concertcue.org',
    LINK_TEXT: 'ConcertCue',
    TEXT1: `
    is a web application for streaming synchronized program
    notes during a live musical performance. These program notes include
    text, images, and other rich media, precisely timed to important
    events in the music itself.`,
  },
  SECOND: {
    TEXT1: `
    Our hope is the ConcertCue will help you better appreciate and
    understand the music you hear. See `,
    LINK_TEXT2: 'concertcue.org',
    TEXT2: ' for more details.',
  },

  CREDITS: {
    PREFIX: `ConcertCue is designed and built by the `,
    SUBJECT: `MIT Music Technology Lab`,
    SUFFIX: ` and is made possible in part with funding from the Knight Foundation.`,
    LINK: 'http://musictech.mit.edu',
  },
};

enum Classes {
  Knight = 'knight',
  Version = 'version',
}

let buildTxt = process.env.REACT_APP_BUILD_TIME;
if (buildTxt) {
  buildTxt = `BUILD: ${buildTxt}`;
}

export const About: React.FC = () => {
  return (
    <StyledAbout>
      <header>{COPY.HEADLINE}</header>
      <p>
        <a href={COPY.FIRST.LINK}>{COPY.FIRST.LINK_TEXT}</a>
        {COPY.FIRST.TEXT1}
      </p>
      <p>
        {COPY.SECOND.TEXT1}
        <a href={COPY.FIRST.LINK}>{COPY.SECOND.LINK_TEXT2}</a>
        {COPY.SECOND.TEXT2}
      </p>
      <p>
        {COPY.CREDITS.PREFIX}
        <a href={COPY.CREDITS.LINK}>{COPY.CREDITS.SUBJECT}</a>
        {COPY.CREDITS.SUFFIX}
        <img className={Classes.Knight} src={KF_LOGO} alt='Knight Foundation' />
      </p>
      <p className={Classes.Version}>
        <small>{buildTxt}</small>
      </p>
    </StyledAbout>
  );
};

const StyledAbout = styled.section`
  ${Mixins.view}
  ${Mixins.padded}

  header {
    ${Typography.headline}
  }

  p {
    font-weight: lighter;
  }

  .${Classes.Knight} {
    display: block;
    width: 100%;
    max-width: 400px;
  }

  .${Classes.Version} {
    position: fixed;
    bottom: 0px;
    color: ${Colors.grayscale04};
  }
`;
