import * as React from 'react';
import styled, { css } from 'styled-components';

import { Colors } from '../../styles';
export interface Props {
  didError?: boolean;
}

enum Classes {
  Color = 'color',
}

export const Spinner: React.FunctionComponent<Props> = (props) => {
  return (
    <StyledSpinner $error={props.didError}>
      <ul>
        <li />
        <li className={Classes.Color} />
        <li />
        <li className={Classes.Color} />
        <li className={Classes.Color} />
        <li />
      </ul>
    </StyledSpinner>
  );
};

const StyledSpinner = styled.div<{ $error?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${(p) =>
    p.$error &&
    css`
      li {
        animation: none !important;
        height: 0.5rem !important;
      }
    `}

  ul {
    margin: 0;
    list-style: none;
    width: 8.6rem;
    height: 122px;
    position: relative;
    padding: 0;
    height: 4rem;

    li {
      position: absolute;
      width: 0.6rem;
      height: 0;
      background-color: ${Colors.white};
      bottom: 0;

      &.${Classes.Color} {
        background-color: ${Colors.orange};
      }

      &:nth-child(1) {
        left: 0;
        animation: sequence1 1.15s ease infinite 0.16s;
      }

      &:nth-child(2) {
        left: 1.6rem;
        animation: sequence2 1.15s ease infinite 0.12s;
      }

      &:nth-child(3) {
        left: 3.2rem;
        animation: sequence1 1.15s ease-in-out infinite 0.23s;
      }

      &:nth-child(4) {
        left: 4.8rem;
        animation: sequence2 1.15s ease-in infinite 0.35s;
      }

      &:nth-child(5) {
        left: 6.4rem;
        animation: sequence1 1.15s ease-in-out infinite 0.46s;
      }

      &:nth-child(6) {
        left: 8rem;
        animation: sequence2 1.15s ease infinite 0.58s;
      }
    }
  }

  @keyframes sequence1 {
    0% {
      height: 19px;
    }
    50% {
      height: 47px;
    }
    100% {
      height: 19px;
    }
  }

  @keyframes sequence2 {
    0% {
      height: 38px;
    }
    50% {
      height: 61px;
    }
    100% {
      height: 38px;
    }
  }
`;
