"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function descending(a, b) {
    return a == null || b == null ? NaN
        : b < a ? -1
            : b > a ? 1
                : b >= a ? 0
                    : NaN;
}
exports.default = descending;
