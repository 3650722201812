import * as React from 'react';
import styled from 'styled-components';

import { Spinner } from '../spinner/Spinner';
export interface Props {
  error?: boolean;
  children?: any;
}

enum Classes {
  Content = 'content',
  Status = 'status',
}

export const Loader: React.FunctionComponent<Props> = (props) => {
  const { children, error } = props;

  return (
    <StyledLoader>
      <div className={Classes.Content}>
        <Spinner didError={error} />
        <div className={Classes.Status}>{children}</div>
      </div>
    </StyledLoader>
  );
};

const StyledLoader = styled.div`
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .${Classes.Content} {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .${Classes.Status} {
      margin: 1rem;
      text-align: center;
      opacity: 0.75;
    }
  }
`;
