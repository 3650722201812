import * as React from 'react';
import { CircularProgress } from './CircularProgress';
import { Story } from 'common';
import { useCueTime } from '../hooks';
import classNames from 'classnames';

interface Props {
  story: Story;
  position: number;
  duration: number;
  isSynchronized: boolean;
}

// Only show the component if the next cue is within this many seconds
const DEFAULT_REMAINING_TIME_TO_DISPLAY_AT = 10;
const DEFAULT_MINIMUM_TIME_BETWEEN_CUES_FOR_VISIBILITY = 10;

/**
 * An experimental feature for indicating how many
 * seconds before the next cue becomes visible in the form
 * of an unwinding circular progress indicator.
 */
export const CueCountdown: React.FC<Props> = ({
  position,
  duration,
  story,
  isSynchronized,
}) => {
  const { remainingTime, durationBetweenCues } = useCueTime(position, story.cues, duration);

  /**
   * Whether or not the countdown should display at all;
   * determined by checking if the duration between cues is greater or equal to
   * either the fade duration or the default minimum
   */
  const shouldDisplay = React.useMemo(() => {
    if (!durationBetweenCues) {
      return false;
    }

    if (!story.fadeDuration) {
      return durationBetweenCues >= DEFAULT_MINIMUM_TIME_BETWEEN_CUES_FOR_VISIBILITY;
    }

    return durationBetweenCues >= story.fadeDuration;
  }, [story, durationBetweenCues]);

  /** How many seconds into the cue before displaying */
  const maxTimeToDisplay = React.useMemo(() => {
    if (!durationBetweenCues) {
      return 0;
    }

    if (!story.holdDuration) {
      return durationBetweenCues - DEFAULT_REMAINING_TIME_TO_DISPLAY_AT;
    }
    return Math.max(
      durationBetweenCues - story.holdDuration,
      DEFAULT_REMAINING_TIME_TO_DISPLAY_AT,
    );
  }, [story, durationBetweenCues]);

  const [remainingSeconds, percentage] = React.useMemo(() => {
    if (remainingTime === null || durationBetweenCues === null) {
      return [0, 0];
    }

    const limitedRemainingTime = Math.min(remainingTime, maxTimeToDisplay);
    const limitedDurationBetweenCues = Math.min(durationBetweenCues, maxTimeToDisplay);

    if (limitedRemainingTime !== remainingTime) {
      return [0, 0];
    }

    return [limitedRemainingTime, limitedRemainingTime / limitedDurationBetweenCues];
  }, [remainingTime, durationBetweenCues, maxTimeToDisplay]);

  const isVisible = React.useMemo(() => {
    return isSynchronized && shouldDisplay && (remainingTime || Infinity) <= maxTimeToDisplay;
  }, [isSynchronized, shouldDisplay, maxTimeToDisplay, remainingTime]);

  return (
    <div
      className={classNames(
        'flex items-center justify-center transition-opacity opacity-0',
        isVisible && 'opacity-100',
      )}
    >
      {remainingTime !== null && (
        <span className='absolute'>{Math.trunc(remainingSeconds)}</span>
      )}
      <CircularProgress value={percentage} />
    </div>
  );
};
CueCountdown.displayName = 'CueCountdown';
