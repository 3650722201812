import * as React from 'react';

import { Slide } from '../../../generic/deck/slide/Slide';

const COPY = 'Here’s how you can customize your ConcertCue experience.';

export const Features: React.FC = () => {
  return (
    <Slide>
      <p>{COPY}</p>
    </Slide>
  );
};
Features.displayName = 'Features';
