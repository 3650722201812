import * as Common from 'common';
import * as React from 'react';

const PERSIST_KEY = 'cc-persisted-app-state';

export interface AppState {
  /** Whether the app or the user is in control of navigation */
  browseMode: 'directed' | 'independent';
  /** Data that is persisted to local storage */
  persisted: {
    /**
     * The version of the persisted data; this must be incremented
     * if any fields are removed or changed
     */
    version: 1;
    /** The last completed global tutorial */
    tutorial: {
      /** The timestamp it was completed */
      completedAt: number;
      version: number;
    } | null;
    /**
     * Completed concert tutorials, set up as a map where the
     * key is the concert id, and the value is an object describing
     * the tutorial completed
     */
    concertTutorials: Record<
      Common.Concert['id'],
      {
        /** The timestamp it was completed */
        completedAt: number;
      }
    >;
  };
}

/** The default app state, used as the context default */
const defaultAppState: AppState = {
  browseMode: 'directed',
  persisted: {
    version: 1,
    tutorial: null,
    concertTutorials: {},
  },
};

/** Helper function for getting the persisted state from local storage, if possible */
const getPersistedState: () => AppState['persisted'] = () => {
  let persisted: AppState['persisted'] = defaultAppState.persisted;

  try {
    const raw = window.localStorage.getItem(PERSIST_KEY);
    if (!raw) {
      throw new Error('No persisted app state');
    }
    const parsed = JSON.parse(raw) as { version: number };
    if (parsed.version !== defaultAppState.persisted.version) {
      throw new Error('Persisted app state version mismatch; resetting to default');
    }
    persisted = parsed as AppState['persisted'];
  } catch (e: unknown) {
    console.error(
      `Could not parse app state from local storage; ${
        (e as Error).message
      }; resetting to default.`,
    );
    window.localStorage.setItem(PERSIST_KEY, JSON.stringify(persisted));
  }

  return persisted;
};

interface AppContextType {
  appState: AppState;
  setAppState: React.Dispatch<React.SetStateAction<AppState>>;
}

const AppContext = React.createContext<AppContextType>({
  appState: defaultAppState,
  setAppState: () => {},
});
AppContext.displayName = 'AppContext';

/** Convenience method to use the context and allow the context object from being used directly */
export const useAppContext = () => React.useContext(AppContext);

/** Provider for the app context */
export const AppContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // Setup our application state
  const [appState, internalSetAppState] = React.useState<AppState>({
    ...defaultAppState,
    persisted: getPersistedState(),
  });

  // Intercepting set state function to save any changes of persisted state to local storage
  const setAppState = React.useCallback(
    (argument: AppState | ((appState: AppState) => AppState)) => {
      let nextState: AppState;
      internalSetAppState((prevState) => {
        if (typeof argument === 'function') {
          nextState = argument(prevState);
        } else {
          nextState = argument;
        }

        if (nextState.persisted !== prevState.persisted) {
          console.log('Persisted state changed; writing to local storage');
          window.localStorage.setItem(PERSIST_KEY, JSON.stringify(nextState.persisted));
        }

        return nextState;
      });
    },
    [internalSetAppState],
  );

  // Memoize the context value so we're not excessively rerendering
  const value = React.useMemo(() => ({ appState, setAppState }), [appState, setAppState]);

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
