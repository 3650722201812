import * as React from 'react';
import styled from 'styled-components';

import { Colors, Typography } from '../../../styles';

interface Props {
  /** Name to display in tab */
  name: string;
  /** Whether this tab is selected */
  selected: boolean;
  /** Triggered when the user taps the tab */
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

/** Renders a single tab for the story tabs */
export const Tab: React.FC<Props> = (props) => {
  const { name, selected, onClick } = props;

  return (
    <StyledTab $selected={selected} onClick={onClick}>
      {name}
    </StyledTab>
  );
};
Tab.displayName = 'Tab';

const StyledTab = styled.div<{ $selected: boolean }>`
  ${Typography.montserrat}
  font-weight: ${(p) => (p.$selected ? 'normal' : 'lighter')};
  opacity: ${(p) => (p.$selected ? 1.0 : 0.4)};
  transition: opacity 250ms;

  background-color: ${Colors.grayscale03};
  border-radius: 8px;
  padding: 6px 8px;

  cursor: pointer;

  &:not(:last-child) {
    margin-right: 6px;
  }
`;
