import * as React from 'react';
import styled from 'styled-components';

import * as Common from 'common';
import { Colors } from '../../styles';

import { CueStatus, CueMark } from './CueMark';
import { Playhead } from './Playhead';
import { Progress } from './Progress';
import * as Variables from './variables';

export interface Props {
  /** The cues to render one the timeline */
  cues: Common.Cue[];
  /** How much time to represent, in seconds */
  duration: number;
  /** The user's position, in seconds; used to indicate an override */
  userPosition?: number;
  /** The actual Chronos specified position */
  actualPosition: number;
  /** Callback triggered when the user taps the timeline; clamped in [0, duration] */
  setPosition: (position: number) => void;
}

export const Timeline: React.FC<Props> = (props: Props) => {
  const timelineRef = React.useRef<HTMLDivElement>(null);
  const draggingRef = React.useRef<boolean>(false);

  const { duration, setPosition } = props;

  const onTouch = React.useCallback(
    (e: any) => {
      const timeline = timelineRef.current;
      if (!timeline) {
        return;
      }

      const x = e.clientX;
      const normalized = Math.min(1.0, Math.max(0, x / timeline.clientWidth));

      const position = normalized * duration;

      setPosition(position);
    },
    [duration, setPosition],
  );

  const touch = React.useCallback(
    (e: any) => {
      e.preventDefault();
      if (e.touches && e.touches.length > 0) {
        onTouch(e.touches[0]);
      }
    },
    [onTouch],
  );

  const mouseDown = React.useCallback(
    (e: MouseEvent) => {
      draggingRef.current = true;
      onTouch(e);
    },
    [onTouch, draggingRef],
  );

  const mouseUp = React.useCallback(() => {
    draggingRef.current = false;
  }, [draggingRef]);

  const mouseMove = React.useCallback(
    (e: MouseEvent) => {
      if (draggingRef.current) {
        onTouch(e);
      }
    },
    [draggingRef, onTouch],
  );

  React.useEffect(() => {
    const timeline = timelineRef.current;
    if (!timeline) {
      return;
    }

    ['touchstart', 'touchend', 'touchmove'].forEach((type) => {
      timeline.addEventListener(type, touch);
    });

    timeline.addEventListener('mousedown', mouseDown);
    timeline.addEventListener('mouseup', mouseUp);
    timeline.addEventListener('mousemove', mouseMove);

    return () => {
      ['touchstart', 'touchend', 'touchmove'].forEach((type) => {
        timeline.removeEventListener(type, touch);
      });

      timeline.removeEventListener('mousedown', mouseDown);
      timeline.removeEventListener('mouseup', mouseUp);
      timeline.removeEventListener('mousemove', mouseMove);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setPosition]);

  // Get the props
  const { cues, userPosition, actualPosition } = props;

  // Draw the cues
  const cueComponents = cues.map((c, i) => {
    // get the next cue
    const next = cues[i + 1];

    // the position to use in comparisons
    const pos = userPosition || actualPosition;

    const isActive = pos >= c.time && (!next || pos < next.time);

    const normalizedPosition = c.time / duration;
    const status: CueStatus = isActive
      ? CueStatus.Active
      : pos < c.time
      ? CueStatus.Ready
      : CueStatus.Visited;

    return <CueMark key={i} normalizedPosition={normalizedPosition} status={status} />;
  });

  // Playhead positions
  const normalizedUserPosition = userPosition ? userPosition / duration : 0;
  const normalizedActualPosition = actualPosition / duration;

  return (
    <StyledTimeline ref={timelineRef}>
      <Progress normalizedPosition={normalizedActualPosition} ghost={!!userPosition} />
      {cueComponents}
      {userPosition ? (
        <Playhead normalizedPosition={normalizedUserPosition} override={true} />
      ) : null}
    </StyledTimeline>
  );
};
Timeline.displayName = 'Timeline';

const StyledTimeline = styled.div`
  position: relative;
  width: 100%;
  height: ${Variables.mobileHeight}px;
  flex-shrink: 0;
  overflow: hidden;
  background-color: ${Colors.colorTimelineDefault};
`;
