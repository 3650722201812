"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLocalStorage = void 0;
const React = __importStar(require("react"));
/**
 * Similar to React's useState hook, but adds effects for automatically loading
 * and saving the state to local storage.
 *
 * The stored object must have a version number which should be
 * incremented whenever fields changes.
 *
 * Only use this hook once in at a high level. If used multiple times with the
 * same key, changes will not be reflected from one instance to the next.
 * Pass down the getter/setter or wrap this in a context instead.
 *
 * @param key: key name for storing this data
 * @param initialState: default values for the data structure to store. Must have a version field.
 * @returns
 */
function useLocalStorage(key, initialState) {
    const [state, setState] = React.useState(initialState);
    // Read in any existing settings; if they are the current version then we can use them;
    // otherwise we should ignore them and remove them from local storage
    React.useEffect(() => {
        const stateString = localStorage.getItem(key);
        // Nothing in local storage, abort
        if (!stateString)
            return;
        // Let's parse it back into json
        const parsedState = JSON.parse(stateString);
        // If there is a version mismatch, remove it and abort
        if (state.version !== parsedState.version) {
            localStorage.removeItem(key);
            return;
        }
        setState(parsedState);
    }, [key, state.version]);
    // updating the state also causes a local storage save
    const setStateExt = React.useCallback((s) => {
        setState(s);
        localStorage.setItem(key, JSON.stringify(s));
    }, []);
    return [state, setStateExt];
}
exports.useLocalStorage = useLocalStorage;
