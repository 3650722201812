import Tippy from '@tippyjs/react';
import * as React from 'react';
import styled from 'styled-components';
import 'tippy.js/dist/tippy.css';

export interface Props {
  definition: {
    word: string;
    content: string;
  };
  onShown: (definition: { word: string; content: string }) => void;
}

/**
 * Renders the definition's word with the definition
 * shown as a popover on tap
 * @param props
 */
export const Definition: React.FC<Props> = (props) => {
  const onShown = () => props.onShown(props.definition);

  const { content, word } = props.definition;

  return (
    <Tippy
      content={content}
      placement='bottom'
      arrow={true}
      onShown={onShown}
      appendTo='parent'
    >
      <StyledCueDefinition>{word}</StyledCueDefinition>
    </Tippy>
  );
};
Definition.displayName = 'Definition';

const StyledCueDefinition = styled.span`
  cursor: help;
  border-bottom: 1px dotted white;
`;
