import * as React from 'react';
import styled, { css } from 'styled-components';

import { CueDeckContainer } from './CueDeckContainer';
import { TimelineContainer } from './TimelineContainer';
import { SizeAdjuster } from './size-adjuster';
import { MovementContext, StoryContext } from '../../lib/contexts';
import { CueProgress, CueCountdown } from './Progress';

const TOOL_TIMEOUT = 3000; // ms

const VIEWER_ZOOM_KEY = 'viewerZoom';
export const VIEWER_MAX_ZOOM = 2;
export const VIEWER_MIN_ZOOM = 0;

interface Props {
  position: number;
  isSynchronized: boolean;
  isFullscreen: boolean;
  override?: () => void;
}

export const StoryViewer: React.FC<Props> = (props: Props) => {
  const [overridePosition, setOverridePosition] = React.useState(0);
  const [showTools, setShowTools] = React.useState(false);

  const [zoom, setZoom] = React.useState(() => {
    const raw = localStorage.getItem(VIEWER_ZOOM_KEY);
    if (raw === null) {
      return 1;
    }

    const z = parseInt(raw);
    if (isNaN(z)) {
      return 1;
    }
    return Math.min(VIEWER_MAX_ZOOM, Math.max(VIEWER_MIN_ZOOM, z));
  });

  const toolTimer = React.useRef<number>();

  // Cleanup timer on unmount
  React.useEffect(() => {
    return () => {
      if (toolTimer.current) {
        clearTimeout(toolTimer.current);
        toolTimer.current = undefined;
      }
    };
  }, []);

  // Whenever the zoom changes, we want to write it to local storage
  // Remember local storage changes do not trigger re-renders
  React.useEffect(() => {
    localStorage.setItem(VIEWER_ZOOM_KEY, JSON.stringify(zoom));
  }, [zoom]);

  const movement = React.useContext(MovementContext);
  const story = React.useContext(StoryContext);

  const { isSynchronized, override } = props;

  const setManualPosition = React.useCallback(
    (position: number) => {
      if (!override) {
        return;
      }
      if (isSynchronized) {
        override();
      }

      setOverridePosition(position);
    },
    [isSynchronized, override, setOverridePosition],
  );

  const tapCallback = React.useCallback(() => {
    if (toolTimer.current) {
      clearTimeout(toolTimer.current);
      toolTimer.current = undefined;
    }

    setShowTools(true);
    toolTimer.current = window.setTimeout(() => {
      setShowTools(false);
    }, TOOL_TIMEOUT);
  }, []);

  if (!movement || !story) {
    return null;
  }

  return (
    <StyledStoryViewer $fullscreen={props.isFullscreen}>
      <CueDeckContainer
        position={props.isSynchronized ? props.position : overridePosition}
        story={story}
        onUserChange={setManualPosition}
        tapCallback={tapCallback}
        zoom={zoom}
        isSynchronized={props.isSynchronized}
        lock={!props.override}
      />
      {story.indicateProgress && (
        <CueProgress
          cues={story.cues}
          position={props.isSynchronized ? props.position : overridePosition}
          duration={movement.duration}
        />
      )}
      <TimelineContainer
        cues={story.cues}
        duration={movement.duration}
        actualPosition={props.position}
        userPosition={props.isSynchronized ? undefined : overridePosition}
        setPosition={setManualPosition}
      />
      <div className='absolute bottom-16 right-2 flex items-center gap-2'>
        <SizeAdjuster
          show={showTools}
          zoom={zoom}
          canDecrease={zoom > VIEWER_MIN_ZOOM}
          canIncrease={zoom < VIEWER_MAX_ZOOM}
          increase={() => {
            setZoom(Math.min(VIEWER_MAX_ZOOM, zoom + 1));
            tapCallback();
          }}
          decrease={() => {
            setZoom(Math.max(VIEWER_MIN_ZOOM, zoom - 1));
            tapCallback();
          }}
        />
        {story.indicateProgress && (
          <CueCountdown
            story={story}
            position={props.position}
            duration={movement.duration}
            isSynchronized={props.isSynchronized}
          />
        )}
      </div>
    </StyledStoryViewer>
  );
};

const StyledStoryViewer = styled.div<{ $fullscreen: boolean }>`
  flex: 1 auto;
  display: flex;
  flex-direction: column;
  min-height: 0;

  ${(p) =>
    p.$fullscreen &&
    css`
      background-color: #1f1f1f;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1002;
    `}
`;
