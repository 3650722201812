"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.testRoot = void 0;
/**
 * Make sure a string is a valid url; throw if not.
 * @param root the root url string to test
 */
function testRoot(root) {
    // Make sure root is not empty
    if (!root) {
        throw new Error('Root may not be empty.');
    }
    // Verify it's a good URL
    const url = new URL(root);
    return url.toString();
}
exports.testRoot = testRoot;
