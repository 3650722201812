import * as React from 'react';
import styled from 'styled-components';

import collapseIcon from '../../../img/ui/collapse.png';

export const Collapse: React.FC = () => {
  return (
    <StyledCollapse>
      <img src={collapseIcon} alt='' />
    </StyledCollapse>
  );
};

const StyledCollapse = styled.div`
  img {
    width: 14px;
  }
`;
