import * as React from 'react';

interface Props {
  /** Normalized 0 -> 1 */
  value: number;
}

export const CircularProgress: React.FC<Props> = ({ value }) => {
  const percent = Math.min(1.0, Math.max(0, value)) * 100;
  return (
    <div
      className='w-10 h-10 rounded-[50%] transition-all'
      style={{
        background: `radial-gradient(closest-side, #1f1f1f 75%, transparent 80% 100%), conic-gradient(#08747c ${percent}%, #606060 0)`,
      }}
    />
  );
};
CircularProgress.displayName = 'CircularProgress';
