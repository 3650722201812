import * as Common from 'common';
import * as React from 'react';
import styled from 'styled-components';

import { Tab } from './Tab';

interface Props {
  /** The stories to list */
  stories: Common.Story[];
  /** When a user taps a story */
  select: (id: Common.Story['id']) => void;
  /** The current story */
  selected?: Common.Story['id'];
}

/** Renders the story tabs */
export const StoryTabs: React.FC<Props> = (props) => {
  // Do not render if there are no stories
  if (props.stories.length <= 1) {
    return null;
  }

  const tabs = props.stories.map((s) => {
    const selected = s.id === props.selected;
    const onClick = () => props.select(s.id);

    return (
      <Tab name={s.name} selected={selected} onClick={onClick} key={s.id} />
    );
  });
  return <StyledStoryTabs>{tabs}</StyledStoryTabs>;
};
StoryTabs.displayName = 'Tabs';

const StyledStoryTabs = styled.div`
  display: flex;
  flex-direction: row;
  padding: 6px 0;
  margin-left: 6px;
`;
