import * as React from 'react';
// import { useParams } from 'react-router';

import {
  ChronosDirectedContext,
  ChronosDirectedType,
  useAppContext,
} from '../../../lib/contexts';
import { generateEndpoint } from '../../../lib/types';
import { useNavigate, useParams } from 'react-router-dom';

const directedMatchesNavigated = (
  directed: ChronosDirectedType,
  navigated: NavigatedParamsType,
) => {
  return (
    directed.concert === navigated.concert &&
    (!directed.piece || directed.piece === navigated.piece) &&
    (!directed.movement || directed.movement === navigated.movement)
  );
};

interface NavigatedParamsType {
  venue: string;
  concert?: string;
  piece?: string;
  movement?: string;
  story?: string;
}

/**
 * This component handles auto navigation during a concert. It does this by
 * comparing the navigated params (concert, piece, movement IDs specified by
 * the path) to the directed data (concert, piece, movement IDs provided by
 * Chronos).
 *
 * It also handles exiting of directed mode to independent browsing mode. To
 * do this we need to differentiate between user-driven and chronos-driven
 * navigation. We achieve this through the use of two separate effects and a
 * reference boolean as documented within the component.
 */
export const AutoNavigator: React.FC = () => {
  // The navigated venue / concert / piece / movement
  const navigated = useParams();

  // The directed (by chronos) concert / piece / movement
  const directed = React.useContext(ChronosDirectedContext);

  // Keep track of the previous concert
  const previousConcert = React.useRef<string | null>(null);

  // Get our browse mode; this will be either independent (user controlled)
  // or directed (chronos controlled)
  const {
    appState: { browseMode },
    setAppState,
  } = useAppContext();

  // We require history to programatically navigate
  const navigate = useNavigate();

  const hasDirected = React.useRef(false);
  const hasOverriden = React.useRef(false);

  const hasDirection = React.useMemo(
    () => browseMode === 'directed' && !!directed.concert,
    [browseMode, directed],
  );

  /**
   * This effect redirects the client to the concert's root upon the end of the
   * concert. This avoids leaving the user at the first slide of the last
   * movement which is a bit odd.
   */
  React.useEffect(() => {
    if (
      previousConcert.current !== null &&
      directed.concert === null &&
      browseMode === 'directed'
    ) {
      // Navigate to…
      const to = generateEndpoint(navigated.venue!);
      navigate(to);
    }

    previousConcert.current = directed.concert;
  }, [directed.concert, browseMode, navigate, navigated.venue]);

  /**
   * This effect should only execute when there has been a navigation event (of
   * any sort). At that time, if there is direction and our redirect effect has
   * not run, then it's safe to say this is a user-driven navigation and we
   * should break into independent mode.
   */
  React.useEffect(() => {
    if (!hasDirection || hasDirected.current) {
      hasDirected.current = false;
      return;
    }

    if (!directedMatchesNavigated(directed, navigated as unknown as NavigatedParamsType)) {
      hasOverriden.current = true;
      setAppState((s) => ({ ...s, browseMode: 'independent' }));
    }

    // The following disable is intentional as per this effect's documentation
    // eslint-disable-next-line
  }, [navigated]);

  /**
   * This is our redirect effect. If we are in directed mode and the directed
   * data does not match the navigated params, then we trigger a redirect and
   * update the hasDirected boolean to true to let the subsequent navigation
   * effect know that it was a chronos driven navigation event.
   */
  React.useEffect(() => {
    // If there is no direction, or we were just overriden by a user event,
    // do nothing
    if (!hasDirection || hasOverriden.current) {
      hasOverriden.current = false;
      return;
    }

    // If directed doesn't match navigated, redirect
    if (!directedMatchesNavigated(directed, navigated as unknown as NavigatedParamsType)) {
      // Navigate to…
      const to = generateEndpoint(
        navigated.venue!,
        directed.concert,
        directed.piece,
        directed.movement,
        navigated.story,
      );

      // do it!
      hasDirected.current = true;
      navigate(to);
    }
    // The following disable is intentional as per this effect's documentation
    // eslint-disable-next-line
  }, [directed, browseMode, hasDirection, navigated]);

  return null;
};
