import * as React from 'react';
import { Slide } from '../../../generic/deck/slide';
import { Demo } from './demo';

const COPY = {
  BODY: `Information panels appear at exactly the right time to match what you're hearing during the performance.`,
};

/** Explains how content is shown */
export const Cues: React.FC = () => {
  return (
    <Slide>
      <p>{COPY.BODY}</p>
      <Demo />
    </Slide>
  );
};
Cues.displayName = 'Cues';
