import * as React from 'react';
import { useParams } from 'react-router';
import { useQuery, useQueryClient } from 'react-query';
import styled from 'styled-components';
import * as Common from 'common';

import { Loader } from '../../../components/loader';
import { SectionHeader } from '../../../components/section-header';
import { Endpoints } from '../../../lib/types';

import { Mixins } from '../../../styles';
import { Schedule } from './schedule';

const Copy = {
  Loading: 'Getting the schedule…',
  Error: 'Error loading schedule',
};

/**
 * Presents the user with a list of scheduled concerts and information
 * about the venue.
 */
export const ScheduleView: React.FC = () => {
  // Extract the desired venue from our URL
  const params = useParams<{ venue: string }>();

  const queryClient = useQueryClient();

  const venueQuery = useQuery(
    ['venues', params.venue],
    ({ signal }) => Common.Clients.getVenue(params.venue!, signal),
    {
      // Load initial data from our list query if available
      initialData: () => {
        return queryClient
          .getQueryData<Common.Venue[]>('venues')
          ?.find((v) => v.id === params.venue);
      },
    },
  );

  const scheduleQuery = useQuery(['schedule', params.venue], ({ signal }) =>
    Common.Clients.getSchedule(params.venue!, signal),
  );

  const venue = venueQuery.data;
  const schedule = scheduleQuery.data;

  // Show a loader if we have not fetched the schedule or it errored
  if (!venue || !schedule) {
    const didError = venueQuery.isError || scheduleQuery.isError;
    return <Loader error={didError}>{didError ? Copy.Error : Copy.Loading}</Loader>;
  }

  const details = <p>{venue.description}</p>;

  return (
    <StyledScheduleView>
      <SectionHeader backTo={Endpoints.Root} details={details}>
        {venue.name || ''}
      </SectionHeader>
      <Schedule schedule={schedule} />
    </StyledScheduleView>
  );
};
ScheduleView.displayName = 'ScheduleView';

const StyledScheduleView = styled.div`
  ${Mixins.view}
`;
