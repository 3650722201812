import * as React from 'react';
import styled from 'styled-components';
import { Colors } from '../../../../styles';

const COPY = {
  /** Copy for the skip button */
  SKIP: 'Skip',
};

interface Props {
  /** What to do when the user clicks it */
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

/**
 * Renders a skip button intended to let the user skip the deck entirely
 */
export const Skip: React.FC<Props> = (props) => {
  return <StyledSkip onClick={props.onClick}>{COPY.SKIP}</StyledSkip>;
};
Skip.displayName = 'Skip';

const StyledSkip = styled.div`
  color: ${Colors.grayscale04};
  font-size: 14px;
  text-transform: lowercase;
  text-align: right;
  top: 14px;
  right: 14px;
  position: fixed;
  cursor: pointer;
  z-index: 1;
`;
