import * as React from 'react';
import { Timeline } from '../story-timeline';
import * as Common from 'common';

export interface Props {
  /** The cues to render one the timeline */
  cues: Common.Cue[];
  /** How much time to represent, in seconds */
  duration: number;
  /** The user's position, in seconds; used to indicate an override */
  userPosition?: number;
  /** The actual Chronos specified position */
  actualPosition: number;
  /** Callback triggered when the user taps the timeline; clamped in [0, duration] */
  setPosition: (position: number) => void;
}

export const TimelineContainer = (props: Props) => {
  return <Timeline {...props} />;
};
