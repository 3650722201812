import * as React from 'react';
import styled from 'styled-components';

import { Mixins } from '../../styles';

export const Unknown: React.FC = () => {
  return (
    <StyledUnknown>
      <p>The requested page could not be found.</p>
    </StyledUnknown>
  );
};

const StyledUnknown = styled.div`
  ${Mixins.view}
  padding: 1rem;
`;
