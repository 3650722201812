"use strict";
/** Interfaces for Path */
Object.defineProperty(exports, "__esModule", { value: true });
exports.isNullTempo = exports.isNullBPM = exports.isNullTimeSig = exports.noTempo = exports.noBPM = exports.noTimeSig = void 0;
/** constant for noTempo (ie, both timeSig and bpm are not set) */
exports.noTimeSig = { num: 0, denom: 0 };
exports.noBPM = { noteLen: null, bpm: 0 };
exports.noTempo = { timeSig: exports.noTimeSig, tempo: exports.noBPM };
/** null queries */
const isNullTimeSig = (ts) => ts.num === 0;
exports.isNullTimeSig = isNullTimeSig;
const isNullBPM = (b) => b.noteLen === null;
exports.isNullBPM = isNullBPM;
const isNullTempo = (td) => td.timeSig.num === 0 && td.tempo.noteLen === null;
exports.isNullTempo = isNullTempo;
