import * as React from 'react';
import { useParams } from 'react-router';

import { chronosDirectedDefaults, ChronosDirectedType } from '../../lib/contexts';
import { Preview } from './Preview';
import { Clients } from 'common';
import { useQuery } from 'react-query';
import { Loader } from '../../components/loader';
import { getChronos } from '../../lib/hooks';
import { CPM } from 'chronos-client';

export const GeneralizedPreview: React.FC = () => {
  // Extract the params for configuring the venue ID from the url path
  const params = useParams<{
    venue: string;
    user: string;
    story?: string;
  }>();

  // Create the preview venue id from the url params
  const previewVenueId = React.useMemo(
    () => `preview_${params.venue}_${params.user}`,
    [params.user, params.venue],
  );

  const [directed, setDirected] = React.useState<ChronosDirectedType>(chronosDirectedDefaults);

  const chronos = getChronos();

  const piecesQuery = useQuery(
    ['preview', directed.concert, directed.piece],
    async ({ signal }) => {
      if (directed.concert) {
        const concert = await Clients.getConcert(directed.concert, signal);
        return concert.program.map((e) => e.piece);
      } else if (directed.piece) {
        const piece = await Clients.getPiecePreview(directed.piece, signal);
        return [piece];
      }
      return [];
    },
    { enabled: !!directed.concert || !!directed.piece },
  );

  /**
   * Setup the Chronos observer for venue data and choose the venue on mount
   */
  React.useEffect(() => {
    const onCPM = (data: CPM | null) => {
      const directed: ChronosDirectedType = {
        connected: true,
        concert: data?.concert || null,
        piece: data?.piece || null,
        movement: data?.movement || null,
      };
      setDirected(directed);
    };

    chronos.on('cpm', onCPM);
    chronos.joinVenue(previewVenueId);

    return () => {
      chronos.off('cpm', onCPM);
    };
  }, [chronos, previewVenueId]);

  React.useEffect(() => {
    chronos.on('refresh', piecesQuery.refetch);

    return () => {
      chronos.off('refresh', piecesQuery.refetch);
    };
  }, [chronos, piecesQuery]);

  if (!piecesQuery.data) {
    return <Loader />;
  }

  return (
    <Preview
      directed={directed}
      pieces={piecesQuery.data}
      previewVenueId={previewVenueId}
      storyId={params.story}
    />
  );
};
GeneralizedPreview.displayName = 'GeneralizedPreview';
