import * as Common from 'common';
import * as React from 'react';
import styled from 'styled-components';

import { Credits } from '../../components/credits';
import { Colors } from '../../styles';

interface Props {
  concert: Common.Concert;
}

const Copy = {
  Survey: 'Survey',
  Credits: 'Credits',
};

enum Classes {
  Content = 'content',
  Buttons = 'buttons',
}

export const Details: React.FC<Props> = (props) => {
  const { concert } = props;
  const { credits } = concert.properties;
  const survey = concert.survey;

  const [showCredits, setShowCredits] = React.useState(false);

  return (
    <StyledDetails onClick={(e) => e.stopPropagation()}>
      <div className={Classes.Content}>{concert.description}</div>
      <div className={Classes.Buttons}>
        {survey && (
          <a href={survey.link} target='_blank' rel='noopener noreferrer'>
            {Copy.Survey}
          </a>
        )}
        {credits && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              setShowCredits(true);
            }}
          >
            {Copy.Credits}
          </button>
        )}
      </div>
      {credits && showCredits && (
        <Credits credits={credits} close={() => setShowCredits(false)} />
      )}
    </StyledDetails>
  );
};

const StyledDetails = styled.div`
  .${Classes.Buttons} {
    margin: 16px 0 8px 0;

    > * {
      background: none;
      border: none;
      margin: 0;
      padding: 0;
      margin-right: 12px;
      color: ${Colors.palette3};
      cursor: pointer;
    }
  }
`;
