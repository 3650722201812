import * as React from 'react';

interface Props {
  /** The current cue index */
  index: number;
}

const FLASH_ANIMATION: Keyframe[] = [{ opacity: 0.5 }, { opacity: 0 }];
const DURATION = 500;

/** Flashes the screen white when the cue changes */
export const Flasher: React.FC<Props> = ({ index }) => {
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const el = ref.current;
    if (!el || index === 0) return;

    el.animate(FLASH_ANIMATION, { duration: DURATION });
  }, [index]);

  return <div ref={ref} className='fixed inset-0 bg-white opacity-0 pointer-events-none' />;
};
Flasher.displayName = 'Flasher';
