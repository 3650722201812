import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import backIcon from '../../img/ui/back.png';
import { InformationToggle } from '../generic/information-toggle/InformationToggle';
import { Colors, Typography } from '../../styles';

interface Props {
  backTo?: string;
  details?: React.ReactNode;
  /** Class to apply to the label container */
  className?: string;
}

export enum Classes {
  Bar = 'section-header-bar',
  Back = 'section-header-back',
  Label = 'section-header-label',
}

export const SectionHeader: React.FC<React.PropsWithChildren<Props>> = (
  props: React.PropsWithChildren<Props>,
) => {
  const [expanded, setExpanded] = React.useState(false);

  const { children, className, backTo, details } = props;

  const toggleDetails = React.useCallback(() => setExpanded((v) => !v), [setExpanded]);

  return (
    <StyledSectionHeader onClick={toggleDetails} className={className}>
      <div className={Classes.Bar}>
        {backTo && (
          <Link className={Classes.Back} to={backTo}>
            <img src={backIcon} alt='<' />
          </Link>
        )}
        <div className={Classes.Label}>{children}</div>
        {details && (
          <InformationToggle
            toggle={(e) => {
              e.stopPropagation();
              toggleDetails();
            }}
            expanded={expanded}
          />
        )}
      </div>
      {details && <StyledDetails $expanded={expanded}>{details}</StyledDetails>}
    </StyledSectionHeader>
  );
};

const StyledSectionHeader = styled.div`
  margin: 0 24px;

  .${Classes.Bar} {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;

    height: 64px;

    .${Classes.Back} {
      width: 44px;
      height: 40px;
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      padding-left: 24px;
      left: -24px;
      position: relative;

      img {
        height: 16px;
        width: auto;
      }
    }

    .${Classes.Label} {
      ${Typography.headlineFont}
      font-size: 17px;
      flex: 1 1 auto;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-left: -24px;
      position: relative;
    }
  }

  border-bottom: 1px solid ${Colors.colorListSeparator};
`;

const StyledDetails = styled.div<{ $expanded: boolean }>`
  display: ${(p) => (p.$expanded ? 'block' : 'none')};

  ${Typography.serif}
  font-size: 14px;
  padding: 0 20px 12px 20px;
`;
