import * as React from 'react';
import styled from 'styled-components';

import { Colors } from '../../../../styles';

interface Props {
  /** Whether to highlight */
  active: boolean;
  /** Click / tap callback */
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

/** Renders a single round dot for deck slide navigation and indication */
export const Dot: React.FC<Props> = (props) => {
  return <StyledDot $active={props.active} onClick={props.onClick} />;
};
Dot.displayName = 'Dot';

const StyledDot = styled.div<{ $active: boolean }>`
  height: 10px;
  width: 10px;

  background-color: ${Colors.white};
  cursor: pointer;

  border-radius: 50%;
  transition: opacity 250ms;
  opacity: ${(p) => (p.$active ? 0.4 : 0.15)};
`;
