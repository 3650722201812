import Hamburger from 'hamburger-react';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Item } from './Item';
import { Endpoints } from '../../lib/types';
import emblem from '../../img/cc_round.png';
import wordmark from '../../img/cc_rectangular.png';
import { Colors } from '../../styles';

const COPY = {
  VENUES: 'Venues',
  ABOUT: 'About',
  TUTORIAL: 'Tutorial',
  FEEDBACK: 'Feedback',
};

/**
 * Renders the top nav bar, menu, and darkened background
 * when the menu is opened
 */
export const NavBar: React.FC = () => {
  const [expanded, setExpanded] = React.useState(false);

  const toggle = () => setExpanded((expanded) => !expanded);

  return (
    <StyledNavBar>
      <StyledBackground $expanded={expanded} onClick={toggle} />
      <StyledBar>
        <NavLink to='/'>
          <img src={emblem} style={{ height: '30px', marginRight: '8px' }} alt='' />
          <img src={wordmark} alt='' />
        </NavLink>
        <div style={{ flex: '1 1 auto' }} />
        <Hamburger toggled={expanded} toggle={toggle} size={18} />
      </StyledBar>
      <StyledNav $expanded={expanded}>
        <Item to={Endpoints.Root} onClick={toggle}>
          {COPY.VENUES}
        </Item>
        <Item to={Endpoints.Tutorial} onClick={toggle}>
          {COPY.TUTORIAL}
        </Item>
        <Item to={Endpoints.About} onClick={toggle}>
          {COPY.ABOUT}
        </Item>
        <Item to={Endpoints.Feedback} onClick={toggle}>
          {COPY.FEEDBACK}
        </Item>
      </StyledNav>
    </StyledNavBar>
  );
};
NavBar.displayName = 'NavBar';

const StyledNavBar = styled.div`
  background-color: ${Colors.colorNavBackground};
`;

const StyledBar = styled.div`
  z-index: 1001;

  position: relative;
  height: 46px;

  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 0 22px;
  user-select: none;

  a {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  img {
    height: 12px;
  }
`;

const StyledNav = styled.nav<{ $expanded: boolean }>`
  z-index: 1001;
  width: 100%;
  top: 46px;
  background-color: ${Colors.colorNavBackground};

  position: absolute;
  transition: all 0.25s;
  opacity: ${(p) => (p.$expanded ? 1.0 : 0)};

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(p) => (p.$expanded ? '24px 0' : 0)};
  pointer-events: ${(p) => (p.$expanded ? 'auto' : 'none')};

  ${(p) =>
    p.$expanded &&
    css`
      > a {
        height: 34px;
        padding: 6px 0;
      }
    `}
`;

const StyledBackground = styled.div<{ $expanded: boolean }>`
  position: absolute;
  transition: background-color 250ms;
  z-index: 1000;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0);

  ${(p) =>
    p.$expanded &&
    css`
      pointer-events: auto;
      background-color: rgba(0, 0, 0, 0.4);

      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
    `}
`;
