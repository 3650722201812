import * as Common from 'common';
import * as React from 'react';
import { SurveyButtons } from './buttons';
import { SurveyMessage } from './message';

interface Props {
  survey: Common.Survey;
  concertName: string;
  takeIt: () => void;
  skipIt: () => void;
}

/**
 * Prompts the user if they'd like to take the survey
 * @param props
 */
export const Prompt: React.FC<Props> = (props) => {
  return (
    <div>
      <SurveyMessage
        concertName={props.concertName}
        message={props.survey.message}
      />
      <SurveyButtons
        link={props.survey.link}
        takeIt={props.takeIt}
        skipIt={props.skipIt}
      />
    </div>
  );
};
Prompt.displayName = 'Prompt';
