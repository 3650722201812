"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSurvey = exports.getPiecePreview = exports.getConcert = exports.getSchedule = void 0;
const axios_1 = require("./axios");
const process_concert_1 = require("../utilities/process-concert");
/**
 * Get the list of concert summaries for the specified venue
 * @param venueId the id of the venue to get concert summaries for
 * @returns a list of concert summaries
 */
const getSchedule = (venueId, signal) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield axios_1.axiosInstance.get(`/venues/${venueId}/schedule`, { signal });
    const currentFilter = (c) => new Date(c.nextPerformance).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0);
    const pastFilter = (c) => new Date(c.nextPerformance).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);
    const sortFunc = (a, b) => {
        const aTime = new Date(a.nextPerformance).getTime();
        const bTime = new Date(b.nextPerformance).getTime();
        return (aTime < bTime ? 1 : 0) + (aTime > bTime ? -1 : 0);
    };
    const past = response.data.concerts.filter(pastFilter).sort(sortFunc);
    const current = response.data.concerts.filter(currentFilter).sort(sortFunc);
    return { past, current };
});
exports.getSchedule = getSchedule;
/**
 * Get the full specified concert object
 * @param concertId the id of the concert to get
 * @returns a full concert object with the entire concert hierarchy of data
 */
const getConcert = (concertId, signal) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield axios_1.axiosInstance.get(`/concerts/${concertId}`, {
        signal,
    });
    const concert = (0, process_concert_1.processConcert)(response.data);
    return concert;
});
exports.getConcert = getConcert;
/**
 * Gets a piece for previewing
 */
const getPiecePreview = (pieceId, signal) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield axios_1.axiosInstance.get(`/preview/${pieceId}`, {
        signal,
    });
    const piece = (0, process_concert_1.processPiece)(response.data);
    return piece;
});
exports.getPiecePreview = getPiecePreview;
/**
 * Gets a survey by its slug
 * @param slug
 * @param signal
 * @returns
 */
const getSurvey = (slug, signal) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield axios_1.axiosInstance.get(`/surveys/${slug}`, { signal });
    return response.data;
});
exports.getSurvey = getSurvey;
