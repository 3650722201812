import { Clients } from 'common';
import * as React from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';

import { Typography } from '../../styles';
import { Loader } from '../loader/Loader';
import { Item } from './Item';

const COPY = {
  VENUES: 'Venues',
  NONE: 'No Venues',
  LOADING: 'Finding Venues…',
  ERROR: 'Something went wrong! Try refreshing!',
};

enum Classes {
  Header = 'header',
  Items = 'items',
}

export const VenueList: React.FC = () => {
  const venuesQuery = useQuery('venues', ({ signal }) =>
    Clients.getVenues(signal),
  );

  let items = null;
  if (venuesQuery.data) {
    if (venuesQuery.data.length === 0) {
      items = <div>{COPY.NONE}</div>;
    } else {
      const mapped = venuesQuery.data.map((v) => <Item venue={v} key={v.id} />);
      items = <div className={Classes.Items}>{mapped}</div>;
    }
  }

  return (
    <StyledVenueList>
      <div className={Classes.Header}>{COPY.VENUES}</div>
      {items}
      {venuesQuery.status !== 'success' && (
        <Loader error={venuesQuery.isError}>
          {venuesQuery.isError ? COPY.ERROR : COPY.LOADING}
        </Loader>
      )}
    </StyledVenueList>
  );
};

const StyledVenueList = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;

  .${Classes.Header} {
    ${Typography.headline}
    margin-left: 114px;
    flex: 0 0 auto;
  }
  .${Classes.Items} {
    flex: 1 0 auto;
  }
`;
