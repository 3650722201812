import * as Common from 'common';
import * as React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { SectionHeader } from '../../components/section-header';
import { StoryTabs } from '../../components/story-viewer/story-tabs';
import { StoryViewer } from '../../components/story-viewer/StoryViewer';
import {
  ChronosDirectedContext,
  ChronosDirectedType,
  MovementContext,
  StoryContext,
} from '../../lib/contexts';
import { useChronosPosition } from '../../lib/hooks';

const Copy = defineMessages({
  NoData: {
    id: 'Preview.NoData',
    defaultMessage: 'Awaiting preview start…',
    description: 'Message to show while we are awaiting data from chronos',
  },
});

interface Props {
  directed: ChronosDirectedType;
  pieces: Common.Piece[];
  previewVenueId: string;
  /** Force a particular story */
  storyId?: string;
}

export const Preview: React.FC<Props> = (props) => {
  const { pieces, directed, storyId } = props;

  const [chosenStoryId, setChosenStoryId] = React.useState(storyId);
  const position = useChronosPosition(directed, 200);

  /**
   * In the event the movement changes and we have a specific story chosen,
   * we want to clear that chosen story id if it doesn't exist in the current movement
   */
  React.useEffect(() => {
    if (pieces && directed.piece && directed.movement && chosenStoryId) {
      const piece = pieces.find((p) => p.id === directed.piece);
      const movement = piece?.movements.find((m) => m.id === directed.movement);

      if (movement && !movement.stories.find((s) => s.id === chosenStoryId)) {
        setChosenStoryId(undefined);
      }
    }
  }, [pieces, directed, chosenStoryId, setChosenStoryId]);

  const piece = React.useMemo(() => {
    return pieces.find((p) => p.id === directed.piece);
  }, [pieces, directed]);

  // Extract the correct movement from the piece based on route
  const movement = React.useMemo(() => {
    return piece && piece.movements.find((m) => m.id === directed.movement);
  }, [piece, directed]);

  const theStoryId = chosenStoryId || (movement && movement.stories[0]?.id);

  // Extract the correct story from the movement based on route
  const story = React.useMemo(() => {
    return movement && movement.stories.find((s) => s.id === theStoryId);
  }, [movement, theStoryId]);

  if (!movement) {
    return (
      <StyledMessage>
        <FormattedMessage {...Copy.NoData} />
      </StyledMessage>
    );
  }

  return (
    <ChronosDirectedContext.Provider value={directed}>
      <MovementContext.Provider value={movement}>
        <StoryContext.Provider value={story}>
          <StyledHeader details={movement.description}>
            <span>{movement.name}</span>
            <StoryTabs
              stories={movement.stories}
              select={setChosenStoryId}
              selected={theStoryId}
            />
          </StyledHeader>
          <StoryViewer isSynchronized={true} position={position} isFullscreen={false} />
        </StoryContext.Provider>
      </MovementContext.Provider>
    </ChronosDirectedContext.Provider>
  );
};

const StyledMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
`;

const StyledHeader = styled(SectionHeader)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  span {
    flex: 0 1 auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
