import * as React from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';

import { DelayedRedirect } from '../../components/delayed-redirect/DelayedRedirect';
import { ExternalRedirect } from '../../components/external-redirect/ExternalRedirect';
import { Loader } from '../../components/loader/Loader';
import { Endpoints } from '../../lib/types';
import { useQuery } from 'react-query';
import { Clients } from 'common';

const COPY = {
  LOADING: 'Retrieving survey…',
  ERROR: 'Survey not found.',
};

/**
 * This view is redirects users to the requested survey
 * link, and provides and error message if the survey
 * is not found. Because it will be used ephemerally,
 * it will retrieve the survey directly from the server.
 */
export const Survey: React.FC = () => {
  const [redirect, setRedirect] = React.useState<string | undefined>();
  const params = useParams<{ survey: string }>();

  const slug = params.survey;

  const surveyQuery = useQuery(
    ['survey', slug],
    ({ signal }) => Clients.getSurvey(slug!, signal),
    { onSuccess: (survey) => setRedirect(survey.link) },
  );

  let inner = null;

  if (surveyQuery.status !== 'success') {
    inner = <Loader error={surveyQuery.isError}>{COPY.LOADING}</Loader>;
  } else {
    if (redirect) {
      inner = <ExternalRedirect to={redirect} />;
    } else {
      inner = (
        <>
          <div>{COPY.ERROR}</div>
          <DelayedRedirect seconds={3} to={Endpoints.Root} />
        </>
      );
    }
  }

  return <StyledSurvey>{inner}</StyledSurvey>;
};

const StyledSurvey = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1 1 auto;
`;
