import { Concert, Movement, Piece, Venue } from 'common/dist/types';

export type ActionType = string;

export type ReducerFunction<S> = (
  state: Readonly<S>,
  action?: any,
) => Readonly<S>;

export type ActionMap<S> = Record<ActionType, ReducerFunction<S>>;

export enum Endpoints {
  Root = '/',
  About = '/about',
  Tutorial = '/tutorial',
  Feedback = '/feedback',
  Surveys = '/s',
  Preview = '/preview',
}

/**
 * Returns a fully formed path for the given hierarchy
 * @param venue the venue id
 * @param concert the concert id
 * @param piece the piece id
 * @param movement the movement id
 * @param story the story id
 */
export function generateEndpoint(
  venue: Venue['id'],
  concert?: Concert['id'] | null,
  piece?: Piece['id'] | null,
  movement?: Movement['id'] | null,
  story?: string | null,
) {
  let endpoint = `/venues/${venue}`;

  if (concert) {
    endpoint = `${endpoint}/concerts/${concert}`;
  }
  if (piece) {
    endpoint = `${endpoint}/pieces/${piece}`;
  }
  if (movement) {
    endpoint = `${endpoint}/movements/${movement}`;
  }
  if (story) {
    endpoint = `${endpoint}/${story}`;
  }

  return `${endpoint}/`;
}

export interface Definition {
  word: string;
  content: string;
}
