import * as Common from 'common';
import * as React from 'react';
import styled, { css } from 'styled-components';

import { Movements } from './Movements';

import { generateEndpoint } from '../../lib/types';
import { ChronosDirectedContext, ChronosProgressContext } from '../../lib/contexts';
import { ListEntry, ListEntryClasses } from '../../components/list-entry';
import { PieceDescription } from './PieceDescription';
import { InformationToggle } from '../../components/generic/information-toggle';
import { Collapse } from '../../components/generic/collapse-button';
import { Colors } from '../../styles';
import { Navigate, useParams } from 'react-router-dom';

interface Props {
  piece: Common.Piece;
}

export const Piece: React.FC<Props> = (props: Props) => {
  const { piece } = props;

  const pieceProgress = React.useContext(ChronosProgressContext).piece;
  const directed = React.useContext(ChronosDirectedContext);

  const progress = pieceProgress[piece.id];

  const hasDirection = directed.concert !== null;
  const active = directed.piece === piece.id;

  const params = useParams<{ venue: string; concert: string; piece: string }>();

  const [described, setDescribed] = React.useState(false);

  const expanded = params.piece === piece.id;

  const skipMovementLayer = piece.movements.length === 1;

  if (expanded && skipMovementLayer) {
    return (
      <Navigate
        to={generateEndpoint(params.venue!, params.concert, piece.id, piece.movements[0].id)}
      />
    );
  }

  const route = generateEndpoint(
    params.venue!,
    params.concert,
    expanded ? undefined : piece.id,
  );
  const subtitle =
    piece.composer && piece.subtitle ? (
      <>
        <div>{piece.composer}</div>
        <div style={{ marginTop: '.5rem' }}>{piece.subtitle}</div>
      </>
    ) : (
      piece.composer || piece.subtitle
    );

  return (
    <StyledPieceEntry
      title={piece.name}
      subtitle={subtitle}
      fixtures={
        <>
          {piece.description && expanded && (
            <InformationToggle
              expanded={described}
              toggle={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setDescribed((v) => !v);
              }}
            />
          )}
          {expanded && <Collapse />}
        </>
      }
      widgets={<PieceDescription expanded={described}>{piece.description}</PieceDescription>}
      to={route}
      progress={progress}
      $hasDirection={hasDirection}
      $active={active}
    >
      {expanded && <Movements movements={piece.movements} />}
    </StyledPieceEntry>
  );
};

const StyledPieceEntry = styled(ListEntry)<{
  $hasDirection: boolean;
  $active: boolean;
}>`
  background-color: ${Colors.grayscale02};

  ${(p) =>
    p.$hasDirection &&
    css`
      .${ListEntryClasses.Titles} {
        color: ${p.$active ? Colors.white : Colors.grayscale045};
      }
    `}
`;
