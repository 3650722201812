"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getVenue = exports.getVenues = void 0;
const axios_1 = require("./axios");
/**
 * Retrieve a list of venues from the ccserver cli api
 * @returns a list of venues
 */
const getVenues = (signal) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield axios_1.axiosInstance.get('/venues', { signal });
    return response.data;
});
exports.getVenues = getVenues;
/**
 * Retrieve a specific venue
 * @param venueId
 * @param signal
 * @returns the specified venue object
 */
const getVenue = (venueId, signal) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield axios_1.axiosInstance.get(`/venues/${venueId}`, { signal });
    return response.data;
});
exports.getVenue = getVenue;
