import * as React from 'react';
import styled from 'styled-components';
import { Colors } from '../../styles';

import { Header } from './Header';

export interface Props {
  close: () => void;
  title?: React.ReactNode | string;
  children: React.ReactNode;
}

export const Popover: React.FC<Props> = (props) => {
  const ignore = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <StyledPopover
      onClick={(e) => {
        e.stopPropagation();
        props.close();
      }}
    >
      <StyledContent onClick={ignore}>
        <Header close={props.close}>{props.title}</Header>
        <div>{props.children}</div>
      </StyledContent>
    </StyledPopover>
  );
};

const StyledPopover = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 16px;
  background-color: ${Colors.backdropDimmer};
  z-index: 11000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const StyledContent = styled.div`
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  max-width: 400px;

  background-color: ${Colors.palette1};
`;
