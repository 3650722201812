import * as React from 'react';
import styled from 'styled-components';

import { BackToRealtime as Button } from '../../../back-to-realtime/BackToRealtime';
import { Slide } from '../../../generic/deck/slide/Slide';

const COPY = {
  BACK1: 'During the concert, you can swipe to view previous or upcoming slides.',
  BACK2: 'When done, tap the orange button to return to live streaming.',
};

export const BackToRealtime: React.FC = () => {
  return (
    <Slide>
      <p>{COPY.BACK1}</p>
      <p>{COPY.BACK2}</p>
      <StyledButton>
        <Button count={10} show={true} backToRealtime={() => {}} />
      </StyledButton>
    </Slide>
  );
};
BackToRealtime.displayName = 'BackToRealtime';

const StyledButton = styled.div`
  width: 100%;
  max-width: 360px;
`;
