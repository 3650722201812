import * as React from 'react';

import { Slide } from '../../../generic/deck/slide';
import { Picker } from './picker';

const COPY = {
  BODY: `Now silence your phone and select your location. Your ConcertCue experience will begin shortly.`,
};

/**
 * Renders the final call to action slide that has the user select a venue
 */
export const Select: React.FC = () => {
  return (
    <Slide>
      <p>{COPY.BODY}</p>
      <Picker />
    </Slide>
  );
};
Select.displayName = 'Select';
