import { css } from 'styled-components';

export const serif = css`
  font-family: Georgia, 'Times New Roman', Times, serif;
`;

export const sansSerif = css`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
`;

export const montserrat = css`
  font-family: 'Montserrat', sans-serif;
`;

export const headlineFont = css`
  ${montserrat}
  text-transform: uppercase;
`;

export const headline = css`
  ${headlineFont};
  padding: 16px 0;
  font-size: 27px;
  font-weight: lighter;
  letter-spacing: 0.2em;
`;
