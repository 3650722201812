import * as Common from 'common';
import * as React from 'react';
import styled from 'styled-components';

import { Colors } from '../../../styles';
import { Prompt } from './prompt';

interface Props {
  /** The name of the concert to mention */
  concertName: string;
  /** The survey to provide */
  survey: Common.Survey;
  /** Let the user escape when this is called */
  dismiss: () => void;
}

/**
 * Prompts the user to take the passed in survey
 * @param props
 */
export const UserSurvey: React.FC<Props> = (props) => {
  return (
    <StyledUserSurvey>
      <Prompt
        survey={props.survey}
        concertName={props.concertName}
        takeIt={props.dismiss}
        skipIt={props.dismiss}
      />
    </StyledUserSurvey>
  );
};
UserSurvey.displayName = 'UserSurvey';

const StyledUserSurvey = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  max-width: 300px;

  background-color: ${Colors.grayscale01};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-weight: 200;
  font-size: 22px;
  color: ${Colors.grayscale05};
  line-height: 1.4;
  text-align: center;
`;
