import * as React from 'react';
import { useQuery } from 'react-query';

import { Clients } from 'common';

import { ConcertTutorialPopover } from '../../components/concert-tutorial-deck';
import { Loader } from '../../components/loader';
import {
  ChronosDirectedContext,
  ChronosProgressContext,
  chronosProgressDefaults,
  ChronosProgressType,
  ConcertContext,
  PieceContext,
} from '../../lib/contexts';
import { useChronosPosition, useSurveyPrimer } from '../../lib/hooks';
import { Outlet, useParams } from 'react-router-dom';

const Copy = {
  Loading: 'Loading Concert…',
  Error: 'Problem loading concert; please try again.',
};

/**
 * View rendered when the path is referring to a specific concert;
 * (i.e. https://concertcue.com/venue/76/concert/123/)
 *
 * Sets the concert and piece context for all child components.
 */
export const Concert: React.FC = () => {
  const directed = React.useContext(ChronosDirectedContext);
  const position = useChronosPosition(directed);

  const params = useParams<{ venue: string; concert: string; piece: string }>();

  const concertQuery = useQuery(['concert', params.concert], ({ signal }) =>
    Clients.getConcert(params.concert!, signal),
  );

  const concert = concertQuery.data;

  // If the piece is specified in our params, then we want to set that context
  // based on our concert and the path params
  const piece = React.useMemo(
    () => concert?.program.find((e) => e.piece.id === params.piece)?.piece,
    [concert, params.piece],
  );

  /**
   * This memoized function looks big n scary, but it's not so bad.
   *
   * We're trying to calculate the normalized progress through the entire
   * concert, each piece, and each movement within. We are only provided with
   * the currently directed movement's progress from Chronos, so we have to
   * construct the missing information for the rest of the movements using some
   * common sense.
   *
   * Any movement before the directed movement must have already happened,
   * therefore we can say its progress is 1.0 (remember, normalized). Any
   * movement after the directed movement has not happened yet and so its
   * progress will be 0.0. The directed movement's progress will be the position
   * as returned by chronos divided by its duration.
   *
   * Using the value calculated for each movement, we can then work our way up
   * the hierarchy and calculate the progress for each piece, and then the
   * concert in its entirety.
   *
   * Note that these calculations can only occur if Chronos has provided a
   * directed movement, and we've loaded the concert object and it is indeed the
   * directed concert. If these conditions are not met, we provide empty
   * defaults.
   */
  const progress: ChronosProgressType = React.useMemo(() => {
    // We can only calculate progress if there is a current movement and we have
    // the full concert object.
    if (concert && directed.movement && directed.concert === concert.id) {
      let normalizedConcertProgress = 0;
      let normalizedPieceProgress: Record<string, number> = {};
      let normalizedMovementProgress: Record<string, number> = {};

      let concertProgress = 0;
      let movementFound = false;
      concert.program
        .map((e) => e.piece)
        .forEach((piece) => {
          let pieceProgress = 0;
          piece.movements.forEach((movement, i) => {
            // TODO: Note this calculation will break if a concert has the same movement repeated
            if (movement.id === directed.movement) {
              movementFound = true;
              pieceProgress += position;
              normalizedMovementProgress[movement.id] = Math.min(
                1.0,
                Math.max(0, position / movement.duration),
              );
            } else {
              if (movementFound) {
                // the movement was already found, so this movement is after and
                // has not started yet
                normalizedMovementProgress[movement.id] = 0.0;
              } else {
                // the movement hasn't been found, so this movement has already
                // finished
                pieceProgress += movement.duration;
                normalizedMovementProgress[movement.id] = 1.0;
              }
            }
          });

          normalizedPieceProgress[piece.id] = Math.min(
            1.0,
            Math.max(0, pieceProgress / piece.duration),
          );

          concertProgress += pieceProgress;
        });

      normalizedConcertProgress = Math.min(
        1.0,
        Math.max(0, concertProgress / concert.duration),
      );

      return {
        concert: normalizedConcertProgress,
        piece: normalizedPieceProgress,
        movement: normalizedMovementProgress,
      };
    }

    return { ...chronosProgressDefaults };
  }, [concert, directed, position]);

  // Prime the concert's survey when necessary
  useSurveyPrimer(concert);

  if (!concert || concertQuery.status !== 'success') {
    const didError = concertQuery.isError;
    return <Loader error={didError}>{didError ? Copy.Error : Copy.Loading}</Loader>;
  }

  return (
    <ConcertContext.Provider value={concert}>
      <PieceContext.Provider value={piece}>
        <ChronosProgressContext.Provider value={progress}>
          <ConcertTutorialPopover />
          <Outlet />
        </ChronosProgressContext.Provider>
      </PieceContext.Provider>
    </ConcertContext.Provider>
  );
};
