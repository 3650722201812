import * as React from 'react';
import styled from 'styled-components';

import { Colors } from '../../../../styles';
import { Slide } from '../../../generic/deck/slide/Slide';

import EXAMPLE from './example.png';

const COPY = {
  /** English */
  EN: {
    /** Instruction explaining that multiple languages are available and to tap the preferred language */
    INSTRUCTION:
      'This concert can be viewed in your preferred language by tapping the corresponding button!',
  },
  /** Spanish: I used google translate */
  ES: {
    INSTRUCTION:
      '¡Este concierto se puede ver un su idioma preferido! Toca el botón​ correspondiente.',
  },
};

enum Classes {
  Alternative = 'alternative',
  Example = 'example',
}

/**
 * Explains that the stories are localized and feature additional languages
 */
export const LocalizedStories: React.FC = () => {
  return (
    <StyledLocalizedStories>
      <p>{COPY.EN.INSTRUCTION}</p>
      <img src={EXAMPLE} alt='languages' className={Classes.Example} />
      <p className={Classes.Alternative}>{COPY.ES.INSTRUCTION}</p>
    </StyledLocalizedStories>
  );
};
LocalizedStories.displayName = 'LocalizedStories';

const StyledLocalizedStories = styled(Slide)`
  .${Classes.Alternative} {
    font-style: italic;
  }

  .${Classes.Example} {
    border: 4px solid ${Colors.grayscale04};
    max-width: 100%;
  }

  p {
    line-height: 110%;
  }
`;
