import * as React from 'react';
import styled from 'styled-components';

import { normalizedToPercentage } from './utility';
import * as Variables from './variables';
import { Colors } from '../../styles';

interface Props {
  normalizedPosition: number;
  ghost?: boolean;
  className?: string;
}

export const Progress: React.FunctionComponent<Props> = React.memo((props: Props) => {
  const style = {
    width: normalizedToPercentage(props.normalizedPosition),
  };

  return <StyledProgress $ghost={props.ghost} className={props.className} style={style} />;
});
Progress.displayName = 'Progress';

const StyledProgress = styled.div<{ $ghost?: boolean }>`
  position: absolute;

  left: -${Variables.mobilePlayheadWidth / 2};
  height: 100%;
  background-color: ${Colors.palette2};
  border-right: ${Variables.mobilePlayheadWidth} solid
    ${(p) => (p.$ghost ? `${Colors.palette7}33` : Colors.palette7)};
`;
