import * as React from 'react';
import styled from 'styled-components';

import { Mixins, Typography } from '../../styles';

const COPY = {
  HEADLINE: 'Feedback',
  MESSAGE: `
    Have thoughts on how ConcertCue should be improved?
    Want ConcertCue at your venue? Let us know using our
  `,
  SUBJECT: 'contact form',
  TERMINATOR: '!',
  LINK: 'https://docs.google.com/forms/d/e/1FAIpQLSeKbLdbDYwgfX-QACBYbCKb6HVhgdC9Z5VP-sd1ON1Of56OQQ/viewform?c=0&w=1',
};

export const Feedback: React.FC = () => {
  return (
    <StyledFeedback>
      <header>{COPY.HEADLINE}</header>
      <p>
        {COPY.MESSAGE}
        <a href={COPY.LINK}>{COPY.SUBJECT}</a>
        {COPY.TERMINATOR}
      </p>
    </StyledFeedback>
  );
};

const StyledFeedback = styled.section`
  ${Mixins.view}
  ${Mixins.padded}

  header {
    ${Typography.headline}
  }

  p {
    font-weight: lighter;
  }
`;
