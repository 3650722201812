import * as React from 'react';
import styled from 'styled-components';

import { Colors } from '../../styles';
import { Navigate } from 'react-router-dom';

interface Props {
  /** The number of seconds until redirect; only read at mount */
  seconds: number;
  /** The location to redirect to */
  to: string;
}

const COPY = {
  REMAINING: 'Redirecting in ',
};

/**
 * Redirects to the given URL after the specified number
 * of seconds. Renders a live countdown till redirect.
 * @param props
 */
export const DelayedRedirect: React.FC<Props> = (props) => {
  const [remaining, setRemaining] = React.useState(Math.round(props.seconds));

  React.useEffect(() => {
    const interval = window.setInterval(() => {
      setRemaining((seconds) => {
        return Math.round(seconds) - 1.0;
      });
    }, 1000);

    return () => {
      window.clearInterval(interval);
    };
  });

  return (
    <StyledDelayedRedirect>
      {remaining === 0 ? <Navigate to={props.to} /> : null}
      <div>
        {COPY.REMAINING} {Math.max(0, remaining)}
      </div>
    </StyledDelayedRedirect>
  );
};
DelayedRedirect.displayName = 'DelayedRedirect';

const StyledDelayedRedirect = styled.section`
  padding: 8px;
  color: ${Colors.grayscale04};
`;
