import * as React from 'react';
import styled from 'styled-components';

import { Colors } from '../../../../styles';

interface Props {
  className?: string;
  children: React.ReactNode;
}

/** Renders a div with a special class to make the deck more responsive */
export const Slide: React.FC<Props> = (props) => {
  return <StyledSlide className={props.className}>{props.children}</StyledSlide>;
};
Slide.displayName = 'Slide';

const StyledSlide = styled.div`
  position: relative;
  padding: 18px;
  padding-bottom: 0;

  width: 100%;
  height: 100%;

  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  overflow: hidden;

  p {
    font-size: 30px;
    font-weight: 200;
    line-height: 140%;
    color: ${Colors.grayscale05};
  }
`;
