import React from 'react';
import { Outlet } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import styled, { createGlobalStyle } from 'styled-components';
import NoSleep from '@uriopass/nosleep.js';
import { NavBar } from './components/nav-bar';
import { TutorialPopover } from './components/tutorial-deck';
import { Colors, Typography } from './styles';
import { AppContextProvider } from './lib/contexts/App';
import { IntlProvider } from 'react-intl';

import 'normalize.css';

const queryClient = new QueryClient();
const noSleep = new NoSleep();

export const App: React.FC = () => {
  React.useEffect(() => {
    document.addEventListener(
      'click',
      function enableNoSleep() {
        document.removeEventListener('click', enableNoSleep, false);
        noSleep.enable();
      },
      false,
    );
  }, []);

  return (
    <IntlProvider locale='en'>
      <StyledApp>
        <QueryClientProvider client={queryClient}>
          <AppContextProvider>
            <GlobalStyle />
            <NavBar />
            <TutorialPopover />
            <Outlet />
          </AppContextProvider>
        </QueryClientProvider>
      </StyledApp>
    </IntlProvider>
  );
};

const StyledApp = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    background-color: ${Colors.gray};
    color: ${Colors.white};
  }

  body {
    ${Typography.sansSerif}
    font-size: 16px;
    touch-action: manipulation;
  }

  p {
    margin-top: 0;
    margin-bottom: 16px;
  }

  a {
    color: ${Colors.palette3};
  }

  * {
    box-sizing: border-box;
  }

  a,
  a:hover,
  a:visited,
  a:link,
  a:active {
    text-decoration: none;
  }

  #root {
    height: 100%;
    max-height: 100%;
  }
`;
