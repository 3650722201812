import * as React from 'react';
import styled from 'styled-components';
import * as Common from 'common';

export const DEFAULT_FADE_DURATION = 3000; //ms - determines how fast cues fade if they do not have their own fade time
export const DEFAULT_UNFADE_DURATION = 250; // ms - determines how fast cues unfade

export interface Props {
  cue: Common.Cue; // the story cue
  story: Common.Story;

  tapCallback?: () => void;

  isSynchronized: boolean;
  visible: boolean;

  /** Override any fade that may exist */
  preventFade?: boolean;
}

/**
 * This component renders a single story Cue and supports fading.
 */
export const Cue: React.FC<Props> = ({
  cue,
  story,
  tapCallback,
  isSynchronized,
  visible,
  preventFade,
}) => {
  const [isFading, setIsFading] = React.useState(false);

  // Calculate the hold and fade durations, if applicable
  const durations = React.useMemo(() => {
    if (!story.holdDuration) {
      return null;
    }
    const hold = story.holdDuration * 1000;
    const fade = story.fadeDuration ? story.fadeDuration * 1000 : DEFAULT_FADE_DURATION;
    return { hold, fade };
  }, [story.holdDuration, story.fadeDuration]);

  // Determine if the fade timer should even be running right now
  const fadeTimerEnabled = React.useMemo(() => {
    return isSynchronized && !preventFade && !!durations && visible;
  }, [isSynchronized, preventFade, durations, visible]);

  // Keep track of the timer
  const holdTimerRef = React.useRef<number | undefined>();
  // Stop and clear the current timer; does not reset the fade state
  const resetCountdown = React.useCallback(() => {
    if (holdTimerRef.current) {
      clearTimeout(holdTimerRef.current);
      holdTimerRef.current = undefined;
    }
  }, []);

  // Start the countdown; also calls reset countdown first
  const beginCountdown = React.useCallback(() => {
    resetCountdown();

    if (durations) {
      holdTimerRef.current = window.setTimeout(() => {
        setIsFading(true);
      }, durations?.hold);
    }
  }, [durations, setIsFading, resetCountdown]);

  // Whenever the fadeTimer is enabled we want to begin the countdown
  React.useEffect(() => {
    if (fadeTimerEnabled) {
      beginCountdown();
    } else {
      resetCountdown();
      setIsFading(false);
    }
  }, [fadeTimerEnabled, beginCountdown, resetCountdown]);

  // User initiated reset of the fade via tap
  const resetFade = React.useCallback(() => {
    beginCountdown();
    setIsFading(false);

    if (tapCallback) {
      tapCallback();
    }
  }, [tapCallback, beginCountdown]);

  // We set the opacity to do the fade
  const style = React.useMemo(
    () =>
      isFading
        ? {
            transition: `opacity ${durations?.fade}ms linear`,
            opacity: 0.0,
          }
        : {
            transition: `opacity ${DEFAULT_UNFADE_DURATION}ms linear`,
            opacity: 1.0,
          },
    [isFading, durations],
  );

  return (
    <StyledCue
      style={style}
      dangerouslySetInnerHTML={{ __html: cue.content }}
      onClick={resetFade}
    />
  );
};
Cue.displayName = 'Cue';

const StyledCue = styled.div`
  padding: 12px 24px;
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  min-height: 0;
  transform: translate(0, 0);
  user-select: none;
  position: relative;

  .img-wrapper {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    flex-direction: column;

    img {
      object-fit: contain;
      max-width: 100%;
      max-height: 100%;
      overflow: hidden;
      // TODO: Disabled due to Safari bug where border
      // would separate from image due to scaling
      // border: 4px solid $palette-5;
    }
  }

  .larger,
  .larger * {
    font-size: 1.35em;
  }

  .smaller,
  .smaller * {
    font-size: 0.85em;
  }

  blockquote {
    background: rgba(255, 255, 255, 0.1);
    border-left: 0.5rem solid rgba(255, 255, 255, 0.1);
    padding: 0.5rem 1rem;

    &:before {
      content: open-quote;
      margin-right: 0.25em;
      vertical-align: -0em;
    }

    &:after {
      content: close-quote;
      margin-left: 0.25em;
      vertical-align: -0em;
    }
  }

  h1,
  h3 {
    margin: 0.25rem 0;
  }

  h1 {
    font-size: 1.75em;
  }

  h3 {
    font-size: 1.15em;
  }
`;
