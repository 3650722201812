import * as React from 'react';
import styled from 'styled-components';

import * as Variables from './variables';
import { normalizedToPercentage } from './utility';
import { Colors } from '../../styles';

interface Props {
  /** The normalized position (0.0 -> 1.0) of this playhead in the timeline */
  normalizedPosition: number;
  /**
   * Whether the user has manually moved the playhead during a live performance;
   * adds an additional style
   */
  override?: boolean;
  className?: string;
}

/**
 * Renders the scrolling playhead indicator that conveys
 * the current position information
 */
export const Playhead: React.FunctionComponent<Props> = React.memo((props: Props) => {
  const style = {
    left: normalizedToPercentage(props.normalizedPosition),
  };

  return (
    <StyledPlayhead $override={props.override} className={props.className} style={style} />
  );
});
Playhead.displayName = 'Playhead';

const StyledPlayhead = styled.div<{ $override?: boolean }>`
  position: absolute;
  width: ${Variables.mobilePlayheadWidth}px;
  height: 100%;
  margin-left: -${Variables.mobilePlayheadWidth / 2}px;
  background-color: ${(p) => (p.$override ? Colors.palette6 : Colors.white)};
  opacity: 1;
  transition: opacity 250ms;
`;
