import * as React from 'react';
import styled from 'styled-components';

import { Colors, Typography } from '../../styles';

export interface HeaderProps {
  close: () => void;
  children: React.ReactNode;
}

enum Classes {
  Title = 'title',
  Close = 'close',
}

export const Header: React.FC<HeaderProps> = (props) => (
  <StyledHeader>
    <span className={Classes.Title}>{props.children}</span>
    <span className={Classes.Close} onClick={props.close}>
      &times;
    </span>
  </StyledHeader>
);

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 16px;
  flex: 0 0 auto;
  border-bottom: 1px solid ${Colors.colorListSeparator};

  .${Classes.Title} {
    ${Typography.montserrat}
    font-weight: lighter;
    flex: 1 0 auto;
  }

  .${Classes.Close} {
    position: relative;
    top: 0.27rem;
    font-size: 2rem;
    line-height: 0.5rem;
    font-weight: lighter;

    cursor: pointer;
  }
`;
