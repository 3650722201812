import * as React from 'react';
import styled from 'styled-components';

import { Colors } from '../../../styles';

const COPY = {
  FINISH: 'Finish',
};

interface Props {
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

/**
 * Renders a Finish button on the last slide of
 * the ConcertTutorialDeck
 * @param props
 */
export const Finish: React.FC<Props> = (props) => {
  return (
    <StyledFinishContainer>
      <StyledFinish onClick={props.onClick}>{COPY.FINISH}</StyledFinish>
    </StyledFinishContainer>
  );
};
Finish.displayName = 'Finish';

const StyledFinishContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const StyledFinish = styled.div`
  height: 48px;
  width: 100%;
  max-width: 240px;
  margin: 8px;

  background-color: ${Colors.grayscale04};
  border-radius: 8px;

  color: $white;
  font-weight: 200;
  font-size: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;
