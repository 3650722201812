import * as React from 'react';
import styled from 'styled-components';

const COPY = {
  PRE: 'Thanks for attending ',
  POST: '! Please, take a quick short survey.',
};

interface Props {
  /** The concert's name */
  concertName: string;
  /**
   * A custom message provided by the survey.
   * A default is used if not provided.
   */
  message?: string;
}

/** Renders the survey prompt message */
export const SurveyMessage: React.FC<Props> = (props) => {
  const { message, concertName } = props;

  const basic = (
    <React.Fragment>
      {COPY.PRE}
      <em>{concertName}</em>
      {COPY.POST}
    </React.Fragment>
  );

  const inner = message ? message : basic;
  return <StyledSurveyMessage>{inner}</StyledSurveyMessage>;
};
SurveyMessage.displayName = 'SurveyMessage';

const StyledSurveyMessage = styled.div`
  padding: 24px;
  padding-bottom: 0;
`;
