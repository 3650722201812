import * as React from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { ChronosDirectedContext } from '../../lib/contexts';

import { PRIMED_SURVEY_KEY, usePrimedSurvey } from '../../lib/hooks';
import { UserSurvey } from './user-survey/UserSurvey';

/**
 * Renders the appropriate survey depending on device type
 */
export const Survey: React.FC = () => {
  const primed = usePrimedSurvey();
  const { venue } = useParams<{ venue: string }>();

  // This counter is a hack to make the component re-render when the user
  // dismisses the survey, thus updating local storage which doesn't normally
  // trigger a re-render.
  const [counter, setCounter] = React.useState(0);

  const { concert, connected } = React.useContext(ChronosDirectedContext);

  const show = primed && primed.venueId === venue && concert === null && connected;

  const dismiss = () => {
    localStorage.removeItem(PRIMED_SURVEY_KEY);
    setCounter(counter + 1);
  };

  if (!show || !primed) {
    return null;
  }

  const surveyComponent = (
    <UserSurvey
      concertName={primed.survey.concert.name}
      survey={primed.survey}
      dismiss={dismiss}
    />
  );

  return <StyledSurvey>{surveyComponent}</StyledSurvey>;
};
Survey.displayName = 'Survey';

const StyledSurvey = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(0, 0, 0, 0.6);

  z-index: 1050;

  padding: 16px;
  flex-direction: column;
`;
