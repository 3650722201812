import * as React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { ChronosCallbacks, ChronosDirectedContext, useAppContext } from '../../lib/contexts';
import { generateEndpoint } from '../../lib/types';
import { useNavigate, useParams } from 'react-router-dom';
import { useCountdown } from './hooks';

const Copy = defineMessages({
  Label: {
    id: 'BackToRealtime.Label',
    defaultMessage: 'Back to live streaming',
  },
  Countdown: {
    id: 'BackToRealtime.Countdown',
    defaultMessage:
      'Returning to live streaming {countdown, plural, =0 {now} one {in # second} other {in # seconds}}…',
  },
});

export interface Props {
  /** Callback function for returning the system to directed time */
  backToRealtime: () => void;
  /** Whether to show the button or not */
  show: boolean;
  /** Countdown value */
  count: number;
}

/**
 * This component renders the "Back to real time" button that appears when the user navigates away from the realtime performance.
 */
export const BackToRealtime: React.FC<Props> = (props) => {
  const { show, backToRealtime, count } = props;

  return (
    <StyledBackToRealtime $visible={show} onClick={backToRealtime}>
      <div>
        <FormattedMessage {...Copy.Label} />
      </div>

      <StyledCountdown>
        <FormattedMessage {...Copy.Countdown} values={{ countdown: show ? count : 0 }} />
      </StyledCountdown>
    </StyledBackToRealtime>
  );
};
BackToRealtime.displayName = 'BackToRealtime';

/**
 * A back to realtime button that is connected to the state
 */
export const BackToRealtimeContainer: React.FC = () => {
  const { logEvent } = React.useContext(ChronosCallbacks);
  const {
    appState: { browseMode },
    setAppState,
  } = useAppContext();
  const navigate = useNavigate();

  const directed = React.useContext(ChronosDirectedContext);
  const navigated = useParams<{
    venue: string;
    story?: string;
  }>();

  // Setup back to realtime click handler
  const backToRealtime = React.useCallback(() => {
    // Navigate to…
    const to = generateEndpoint(
      navigated.venue!,
      directed.concert,
      directed.piece,
      directed.movement,
      navigated.story,
    );

    // do it!
    navigate(to);
    setAppState((s) => {
      return { ...s, browseMode: 'directed' };
    });
    logEvent('setRealtime', true);
  }, [setAppState, logEvent, navigate, navigated, directed]);

  // Should we be visible?
  const show = React.useMemo(
    () => browseMode === 'independent' && directed.concert !== null,
    [browseMode, directed.concert],
  );

  const count = useCountdown(show, backToRealtime);

  return <BackToRealtime show={show} count={count} backToRealtime={backToRealtime} />;
};
BackToRealtimeContainer.displayName = 'BackToRealtimeContainer';

const StyledCountdown = styled.span`
  font-size: 10px;
`;

const StyledBackToRealtime = styled.div<{ $visible: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  height: ${(p) => (p.$visible ? '2.5rem' : 0)};
  width: 80%;
  margin: ${(p) => (p.$visible ? 10 : 0)}px auto 0 auto;
  overflow: hidden;

  background-color: orangered;
  border-bottom: ${(p) => (p.$visible ? 1 : 0)}px solid red;
  user-select: none;
  cursor: pointer;
  transition: all 0.25s;

  border-radius: 10px;

  > * {
    color: rgba(255, 255, 255, 0.75);
  }

  z-index: 1003;
`;
