import Tippy from '@tippyjs/react';
import * as React from 'react';
import styled from 'styled-components';

import { Slide } from '../../../generic/deck/slide/Slide';

const COPY = {
  DEFINITIONS: 'Tap any underlined word to find out what it means.',
  EXAMPLE: {
    WORD: 'moderato',
    DEFINITION: 'With a medium speed tempo',
  },
};

enum Classes {
  Definition = 'definition',
}

export const PopupDefinitions: React.FC = () => {
  return (
    <StyledPopupDefinitions>
      <p>{COPY.DEFINITIONS}</p>
      <p>
        <Tippy
          content={COPY.EXAMPLE.DEFINITION}
          arrow={true}
          sticky={true}
          duration={200}
          animation='scale'
        >
          <span className={Classes.Definition}>{COPY.EXAMPLE.WORD}</span>
        </Tippy>
      </p>
    </StyledPopupDefinitions>
  );
};
PopupDefinitions.displayName = 'PopupDefinitions';

const StyledPopupDefinitions = styled(Slide)`
  .${Classes.Definition} {
    cursor: help;
    border-bottom: 1px dotted white;
    outline: none;
  }
`;
