import * as React from 'react';
import styled from 'styled-components';

import { VenueList } from '../../components/venue-list/VenueList';
import { Mixins } from '../../styles';

/**
 * Top level component visible when the user is at the root of the app
 *
 * Retrieves the venues
 */
export const Landing: React.FC = () => {
  return (
    <StyledLandingView>
      <VenueList />
    </StyledLandingView>
  );
};

const StyledLandingView = styled.div`
  ${Mixins.view}
  ${Mixins.padded}
`;
