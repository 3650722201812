import * as React from 'react';
import styled from 'styled-components';

import { Colors } from '../../../../styles';

interface Props {
  /**
   * The provided hook that determines if the popover is visible
   */
  isVisible: boolean;
  children: React.ReactNode;
}

/**
 * Popover container used for tutorials
 * @param props
 */
export const TutorialPopoverContainer: React.FC<Props> = ({ isVisible, children }) => {
  if (!isVisible) {
    return null;
  }

  return <StyledTutorialPopover>{children}</StyledTutorialPopover>;
};
TutorialPopoverContainer.displayName = 'TutorialPopoverContainer';

const StyledTutorialPopover = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  z-index: 2000;

  display: flex;
  flex-direction: column;

  background-color: ${Colors.grayscale01};
`;
