import * as React from 'react';
import { useAppContext } from '../../lib/contexts';

/**
 * Describes the current version of the
 * concert level tutorials. Incrementing this
 * will ensure users will see it again for a given
 * concert.
 */
export const CONCERT_TUTORIAL_VERSION = 1;

/**
 * The amount of time, in milliseconds,
 * to wait before showing the user a concert
 * tutorial again. This expire should generally be
 * shorter than the global tutorial expire, since
 * its main purpose is to prevent the tutorial for
 * popping up for each concert if a user decides to
 * browse a few of them consecutively.
 *
 * Currently 4 hours
 */
export const CONCERT_TUTORIAL_EXPIRE = 4 * 60 * 60 * 1000;

export const useAcknowledgeConcertTutorial = (concertId: string | undefined) => {
  const { setAppState } = useAppContext();
  const acknowledge = React.useCallback(() => {
    if (concertId) {
      setAppState((prev) => ({
        ...prev,
        persisted: {
          ...prev.persisted,
          concertTutorials: {
            ...prev.persisted.concertTutorials,
            [concertId]: { completedAt: Date.now() },
          },
        },
      }));
    }
  }, [setAppState, concertId]);

  return acknowledge;
};

export const useIsConcertTutorialVisible = (concertId: string | null) => {
  const { appState } = useAppContext();
  const isVisible = React.useMemo(() => {
    if (!concertId) return false;
    const tutorial = appState.persisted.concertTutorials[concertId];
    return !tutorial || tutorial.completedAt + CONCERT_TUTORIAL_EXPIRE < Date.now();
  }, [appState.persisted.concertTutorials, concertId]);

  return isVisible;
};
