import * as React from 'react';
import SwipeableViews from 'react-swipeable-views';
import styled from 'styled-components';

import { Colors } from '../../styles';
import { Dots } from '../generic/deck/dots/Dots';
import { TutorialPopoverContainer } from '../generic/deck/popover/Popover';
import { Skip } from '../generic/deck/skip/Skip';
import * as Slides from './slides';
import { useAcknowledgeTutorial, useIsTutorialVisible } from './hooks';

/** The slides in the order we want them rendered */
const slides = [Slides.Welcome, Slides.Cues, Slides.Select];

interface Props {
  /** Hides the skip button when viewed as a page rather than a popover */
  hideSkip?: boolean;
}

/** Renders the deck of informative intro slides */
export const TutorialDeck: React.FC<Props> = (props) => {
  const [index, setIndex] = React.useState(0);

  const count = slides.length;
  const children = slides.map((S, i) => <S key={i} />);

  const onClick = useAcknowledgeTutorial();

  return (
    <StyledTutorialDeck>
      {props.hideSkip ? null : <Skip onClick={onClick} />}
      <StyledSwipeableViews
        index={index}
        onChangeIndex={setIndex}
        containerStyle={{ height: '100%' }}
        enableMouseEvents={true}
      >
        {children}
      </StyledSwipeableViews>
      <Dots count={count} index={index} goTo={(i) => setIndex(i)} />
    </StyledTutorialDeck>
  );
};
TutorialDeck.displayName = 'TutorialDeck';

export const TutorialPopover: React.FC = () => {
  const isVisible = useIsTutorialVisible();
  return (
    <TutorialPopoverContainer isVisible={isVisible}>
      <TutorialDeck />
    </TutorialPopoverContainer>
  );
};

const StyledTutorialDeck = styled.div`
  flex: 1 1 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${Colors.grayscale01};
`;

const StyledSwipeableViews = styled(SwipeableViews)`
  flex: 1 1 0;
  flex-basis: 0;
`;
