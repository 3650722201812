import * as React from 'react';
import styled from 'styled-components';

import { Slide } from '../../../generic/deck/slide/Slide';

import swipe from './swipe.gif';

const COPY = {
  INSTRUCTION: 'On an iOS device? Minimize the browser bar by swiping up',
  ALT: 'Swipe up on the address bar',
};

/**
 * Informs iOS users on how to minimize the Safari
 * address / navigation bar by swiping upward.
 * @param props
 */
export const MinimizeUI: React.FC = (props) => {
  return (
    <StyledMinimizeUI>
      <img src={swipe} alt={COPY.ALT} />
      <p>{COPY.INSTRUCTION}</p>
    </StyledMinimizeUI>
  );
};
MinimizeUI.displayName = 'MinimizeUI';

const StyledMinimizeUI = styled(Slide)`
  img {
    max-width: 100%;
  }
`;
