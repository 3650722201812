import { css } from 'styled-components';
import { Colors } from '.';

export const view = css`
  flex: 1 1 auto;
  height: 1rem;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
`;

export const padded = css`
  padding: 0 20px;
`;

export const slimProgress = css`
  -webkit-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  height: 2px;

  &::-webkit-progress-bar {
    background-color: transparent;
  }

  &::-webkit-progress-value {
    background-color: ${Colors.orange};
  }
`;
