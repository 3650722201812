import * as React from 'react';

import { Slide } from '../../../generic/deck/slide';
import { SizeAdjuster } from '../../../story-viewer/size-adjuster';

const COPY = {
  ADJUSTER:
    'Text too small? Tap anywhere to bring up the font adjuster. Use the + and - buttons.',
};

const MAX_SIZE = 3;
const MIN_SIZE = 1;

export const FontZoomer: React.FC = () => {
  const [zoom, setZoom] = React.useState(2);

  const size = zoom / 6.0 + 1.5;
  const style = {
    fontSize: `${size * 1}em`,
  };

  return (
    <Slide>
      <p style={style}>{COPY.ADJUSTER}</p>
      <SizeAdjuster
        show={true}
        zoom={zoom}
        canDecrease={zoom > MIN_SIZE}
        canIncrease={zoom < MAX_SIZE}
        decrease={() => setZoom((zoom) => zoom - 1)}
        increase={() => setZoom((zoom) => zoom + 1)}
      />
    </Slide>
  );
};
FontZoomer.displayName = 'FontZoomer';
