import * as React from 'react';
import styled from 'styled-components';

import { Details } from './Details';
import { Pieces } from './Pieces';
import { SectionHeader } from '../../components/section-header';
import { generateEndpoint } from '../../lib/types';
import { Mixins } from '../../styles';
import { ConcertContext } from '../../lib/contexts';
import { useParams } from 'react-router-dom';

enum Classes {
  SlimProgress = 'slim-progress',
}

/**
 * View rendered when the path is referring to a specific concert;
 * (i.e. https://concertcue.com/venue/76/concert/123/)
 */
export const ConcertOverview: React.FC = () => {
  const params = useParams<{ venue: string; concert: string }>();
  const backTo = generateEndpoint(params.venue!);

  const concert = React.useContext(ConcertContext);
  const progress = undefined;

  if (!concert) {
    return null;
  }

  const details = <Details concert={concert} />;

  return (
    <StyledConcertOverview>
      <BorderlessHeader backTo={backTo} details={details}>
        {concert.name}
      </BorderlessHeader>
      {progress !== undefined && (
        <progress className={Classes.SlimProgress} value={progress} />
      )}

      <Pieces pieces={concert.program.map((p) => p.piece)} />
    </StyledConcertOverview>
  );
};

const BorderlessHeader = styled(SectionHeader)`
  border-bottom: none;
`;

const StyledConcertOverview = styled.div`
  ${Mixins.view}

  .${Classes.SlimProgress} {
    ${Mixins.slimProgress}
  }
`;
