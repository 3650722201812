import * as React from 'react';
import SwipeableViews, { SwipeableViewsProps } from 'react-swipeable-views';
import ResizeObserver from 'resize-observer-polyfill';
import styled, { css } from 'styled-components';

import { Cue } from './Cue';
import { Definitions } from './Definitions';
import * as Common from 'common';
import { Typography } from '../../styles';
import { Flasher } from '../story-viewer/Progress/Flasher';

interface Props {
  isSynchronized: boolean;
  position: number;
  zoom: number;
  story: Common.Story;
  shouldCrossfade: boolean;
  lock?: boolean;
  onDefinitionShown: (definition: { word: string; content: string }) => void;
  tapCallback?: () => void;
  onUserChange?: (position: number) => void; // callback when the user swipes forward or back a slide
}

export const CueDeck: React.FC<Props> = (props: Props) => {
  const [height, setHeight] = React.useState(0);
  const ref = React.useRef<HTMLDivElement>(null);
  const [, setRenderDefinitions] = React.useState(0);

  React.useEffect(() => {
    const container = ref.current;
    if (!container) {
      return;
    }
    const ro = new ResizeObserver((entries) => {
      const entry = entries[0];
      if (entry) {
        const { height } = entry.contentRect;
        setHeight(height);
      }
    });
    ro.observe(container);

    return () => {
      ro.disconnect();
    };
  });

  React.useLayoutEffect(() => {
    setRenderDefinitions((v) => v + 1);
  }, [props.story]);

  // Add an empty slide to the start if the first cue is after the start
  const needsEmptySlide =
    props.story.cues[0]?.time > 0 &&
    (props.story.cues[0]?.position?.barIdx > 0 || props.story.cues[0]?.position?.pct > 0);

  // Calculate the current cue index
  let currentIndex = 0;
  {
    const reversedCues = [...props.story.cues].reverse();
    currentIndex = reversedCues.findIndex((c) => c.time <= props.position);
    if (currentIndex === -1) {
      currentIndex = 0;
    } else {
      currentIndex = Math.max(
        props.story.cues.length - currentIndex - (needsEmptySlide ? 0 : 1),
        0,
      );
    }
  }

  // Map the story's cues to Cue components
  let children = props.story.cues.map((cue, index) => (
    <Cue
      key={index}
      cue={cue}
      story={props.story}
      isSynchronized={props.isSynchronized}
      tapCallback={props.tapCallback}
      preventFade={index === 0}
      visible={index + (needsEmptySlide ? 1 : 0) === currentIndex}
    />
  ));

  // Insert the empty slide if needed
  if (needsEmptySlide) {
    const zeroCue = <div key='zeroth' />;
    children = [zeroCue, ...children];
  }

  const { story, onUserChange } = props;
  const onSwiperChangeIndex = React.useCallback(
    (index: number) => {
      // on a user swipe, notify our call back method with the position of the indexed cue
      if (onUserChange) {
        const cue = story.cues[index - (needsEmptySlide ? 1 : 0)];
        if (cue) {
          onUserChange(cue.time);
        }
      }
    },
    [needsEmptySlide, story, onUserChange],
  );

  // SwipableViews settings
  // https://react-swipeable-views.com/api/api/
  const settings: SwipeableViewsProps = {
    disabled: props.lock,
    enableMouseEvents: true,
    animateTransitions: !props.shouldCrossfade,
    slideStyle: { display: 'flex' },
    index: currentIndex,
    onChangeIndex: onSwiperChangeIndex,
  };

  const views = (
    // @ts-ignore
    <SwipeableViews {...settings} id='cuedeck' containerStyle={{ height }}>
      {children}
    </SwipeableViews>
  );

  // Return the populated deck
  return (
    <StyledCueDeck $zoom={props.zoom} $crossfade={props.shouldCrossfade} ref={ref}>
      {props.isSynchronized && story.indicateProgress && <Flasher index={currentIndex} />}
      {views}
      {ref.current && (
        <Definitions deck={ref.current} onDefinitionShown={props.onDefinitionShown} />
      )}
    </StyledCueDeck>
  );
};
CueDeck.displayName = 'CueDeck';

const StyledCueDeck = styled.div<{
  $zoom: number;
  $crossfade: boolean;
}>`
  font-weight: 300;
  line-height: 1.4;

  ${Typography.montserrat}

  flex: 1 1 0;
  overflow: hidden;
  font-size: $font-zoom-0;

  :global .react-swipeable-view-container {
    height: 100%;
  }

  font-size: ${(p) => {
    switch (p.$zoom) {
      case 1:
        return 1.3;
      case 2:
        return 1.6;
      case 3:
        return 2;
      default:
        return 1;
    }
  }}rem;

  ${(p) =>
    p.$crossfade &&
    css`
      > div > div > div {
        // todo: zero until we can figure out proper crossfading
        transition: opacity 0s;
        &[aria-hidden='true'] {
          opacity: 0;
        }

        &[aria-hidden='false'] {
          opacity: 1;
        }
      }
    `}
`;
