import * as React from 'react';
import styled from 'styled-components';

import { normalizedToPercentage } from './utility';
import * as Variables from './variables';

import { Colors } from '../../styles';

export enum CueStatus {
  /** Cue has not been shown yet */
  Ready = 'READY',
  /** Cue is currently being shown */
  Active = 'ACTIVE',
  /** Cue was shown and is no longer visible */
  Visited = 'VISITED',
}

interface Props {
  /** The normalized position (0.0 -> 1.0) of this cue in the timeline */
  normalizedPosition: number;
  /** The upcoming / current / past indication */
  status: CueStatus;
  className?: string;
}

/**
 * Renders an indicator that denotes the position of a cue in the timeline
 */
export const CueMark: React.FunctionComponent<Props> = React.memo((props: Props) => {
  const style = {
    left: normalizedToPercentage(props.normalizedPosition),
  };

  return <StyledCueMark $status={props.status} style={style} className={props.className} />;
});
CueMark.displayName = 'CueMark';

const StyledCueMark = styled.div<{ $status: CueStatus }>`
  position: absolute;

  width: ${Variables.mobileCueMarkerWidth}px;
  height: ${Variables.mobileCueMarkerHeight}px;
  margin-left: -${Variables.mobileCueMarkerWidth / 2}px;
  margin-top: -${Variables.mobileCueMarkerHeight / 2}px;
  top: 50%;
  background-color: ${(p) => {
    switch (p.$status) {
      case CueStatus.Active:
        return Colors.palette4;
      case CueStatus.Visited:
        return Colors.palette3;
      default:
        return Colors.palette7;
    }
  }};
`;
