import * as React from 'react';

const DEFAULT_TIMEOUT_DURATION = 10;

export const useCountdown = (
  active: boolean,
  onEnd: () => void,
  duration: number = DEFAULT_TIMEOUT_DURATION,
): number => {
  const [countdown, setCountdown] = React.useState(duration);

  // Setup our countdown timeout and touch detector reset
  React.useEffect(() => {
    if (!active) {
      return;
    }

    setCountdown(duration);

    // Factory function for creating the countdown interval
    const setupInterval = () => {
      return window.setInterval(() => {
        setCountdown((v) => {
          const next = v - 1;
          if (next === -1) {
            // This timeout is necessary to avoid some conflicts with state updating and routing
            window.setTimeout(() => onEnd(), 1);
            window.clearInterval(interval);
          }
          return Math.max(0, next);
        });
      }, 1000);
    };

    // This is the initial interval timer
    let interval = setupInterval();

    // Whenever there is activity, we want to reset the time remaining and
    // restart the timer.
    const onActivity = (ev: MouseEvent) => {
      setCountdown(duration);
      window.clearInterval(interval);
      interval = setupInterval();
    };

    window.addEventListener('mousedown', onActivity);
    return () => {
      window.clearInterval(interval);
      window.removeEventListener('mousedown', onActivity);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  return countdown;
};
