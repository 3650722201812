import * as Common from 'common';
import * as React from 'react';

import { Piece } from './Piece';

interface PiecesProps {
  pieces: Common.Piece[];
}

export const Pieces: React.FC<PiecesProps> = (props: PiecesProps) => {
  const { pieces } = props;
  const list = pieces.map((p) => {
    return <Piece key={p.id} piece={p} />;
  });

  return <>{list}</>;
};
