"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.numbers = void 0;
function number(x) {
    return x === null ? NaN : +x;
}
exports.default = number;
function* numbers(values, valueof) {
    if (valueof === undefined) {
        for (let value of values) {
            if (value != null && (value = +value) >= value) {
                yield value;
            }
        }
    }
    else {
        let index = -1;
        for (let value of values) {
            if ((value = valueof(value, ++index, values)) != null && (value = +value) >= value) {
                yield value;
            }
        }
    }
}
exports.numbers = numbers;
