"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Chronos = void 0;
const events_1 = require("events");
const socketcluster_client_1 = require("socketcluster-client");
const clocksync_1 = require("./clocksync");
const time_tracker_1 = require("./time-tracker");
/**
 * Events emitted:
 * - status: 'connected' | 'error' | 'closed'
 * - cpm: CPM | null
 * - refresh: data
 */
class Chronos extends events_1.EventEmitter {
    constructor(host, port, isDirector = false) {
        super();
        /** return the current movement time, in seconds */
        this.getMvtTime = (debounce = true) => {
            // get current movement time from server time
            const srvTime = this.clock.getTime();
            let mvtTime = (0, time_tracker_1.calcTime)(this.timeTracker.state, srvTime);
            // for debouncing: don't allow time to go backwards if this is a small backwards adjustment.
            const window = 1.0;
            if (debounce && this.prevMvtTime - window < mvtTime && mvtTime < this.prevMvtTime) {
                mvtTime = this.prevMvtTime;
            }
            else {
                this.prevMvtTime = mvtTime;
            }
            return mvtTime;
        };
        /** estimate of common server time */
        this.getServerTime = () => {
            return this.clock.getTime();
        };
        /** speed factor of live time tracking */
        this.getMvtSpeed = () => {
            return this.timeTracker.state.slope;
        };
        // create socket and clock
        const secure = port === 443;
        this.socket = (0, socketcluster_client_1.create)({ hostname: host, port, secure });
        this.connectionStatus = 'connecting';
        this.clock = new clocksync_1.ClockSync((time) => {
            this.socket.transmit('clockPing', time);
        });
        // initialize member variables
        this.isDirector = isDirector;
        this.channel = '';
        this.timeTracker = new time_tracker_1.TimeTracker(this.clock);
        this.prevMvtTime = 0;
        this.lastInfoRequestTime = 0;
        this.clientCount = 0;
        // needed to avoid compile errors in the handler setup code.
        const socket = this.socket;
        const clock = this.clock;
        // set up event handlers
        (() => __awaiter(this, void 0, void 0, function* () {
            var _a, e_1, _b, _c;
            try {
                for (var _d = true, _e = __asyncValues(socket.listener('error')), _f; _f = yield _e.next(), _a = _f.done, !_a; _d = true) {
                    _c = _f.value;
                    _d = false;
                    const { error } = _c;
                    console.error(`Socket error ${socket.id} ${error.name}`);
                    this.connectionStatus = 'error';
                    this.emit('status', 'error');
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (!_d && !_a && (_b = _e.return)) yield _b.call(_e);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }))();
        (() => __awaiter(this, void 0, void 0, function* () {
            var _g, e_2, _h, _j;
            try {
                for (var _k = true, _l = __asyncValues(socket.listener('connect')), _m; _m = yield _l.next(), _g = _m.done, !_g; _k = true) {
                    _j = _m.value;
                    _k = false;
                    const _ = _j;
                    // console.log(`connected ${socket.id}`);
                    this.connectionStatus = 'connected';
                    this.emit('status', 'connected');
                    // don't track director connections.
                    if (!isDirector)
                        socket.transmit('setID', getUID());
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (!_k && !_g && (_h = _l.return)) yield _h.call(_l);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }))();
        (() => __awaiter(this, void 0, void 0, function* () {
            var _o, e_3, _p, _q;
            try {
                for (var _r = true, _s = __asyncValues(socket.listener('close')), _t; _t = yield _s.next(), _o = _t.done, !_o; _r = true) {
                    _q = _t.value;
                    _r = false;
                    const _ = _q;
                    // console.log(`closed`);
                    this.connectionStatus = 'closed';
                    this.emit('status', 'closed');
                }
            }
            catch (e_3_1) { e_3 = { error: e_3_1 }; }
            finally {
                try {
                    if (!_r && !_o && (_p = _s.return)) yield _p.call(_s);
                }
                finally { if (e_3) throw e_3.error; }
            }
        }))();
        (() => __awaiter(this, void 0, void 0, function* () {
            var _u, e_4, _v, _w;
            try {
                for (var _x = true, _y = __asyncValues(socket.receiver('clockPong')), _z; _z = yield _y.next(), _u = _z.done, !_u; _x = true) {
                    _w = _z.value;
                    _x = false;
                    const data = _w;
                    const [localTime, srvTime] = data;
                    clock.addClockPong(localTime, srvTime);
                }
            }
            catch (e_4_1) { e_4 = { error: e_4_1 }; }
            finally {
                try {
                    if (!_x && !_u && (_v = _y.return)) yield _v.call(_y);
                }
                finally { if (e_4) throw e_4.error; }
            }
        }))();
    }
    /** Disconnect from chronos, being sure to leave venue first.
     * May cause some venue-leaving events to get emitted. Will also emit 'status' 'closed'
     * After disconnecting, this object becomes useless. So it must then be recreated from
     * scratch
     */
    disconnect() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.leaveVenue(true);
            this.clock.stop();
            this.socket.disconnect();
        });
    }
    /** Join a venue. will emit 'cpm' */
    joinVenue(venueId) {
        return __awaiter(this, void 0, void 0, function* () {
            const ch = `venue.${venueId}`;
            if (ch === this.channel)
                return;
            yield this.leaveVenue(false);
            this.channel = ch;
            // Subscribe to a channel.
            const channel = this.socket.subscribe(this.channel);
            yield channel.listener('subscribe').once();
            // handle data arriving at this channel
            (() => __awaiter(this, void 0, void 0, function* () {
                var _a, e_5, _b, _c;
                try {
                    for (var _d = true, channel_1 = __asyncValues(channel), channel_1_1; channel_1_1 = yield channel_1.next(), _a = channel_1_1.done, !_a; _d = true) {
                        _c = channel_1_1.value;
                        _d = false;
                        const data = _c;
                        this._onVenueData(data);
                    }
                }
                catch (e_5_1) { e_5 = { error: e_5_1 }; }
                finally {
                    try {
                        if (!_d && !_a && (_b = channel_1.return)) yield _b.call(channel_1);
                    }
                    finally { if (e_5) throw e_5.error; }
                }
            }))();
            // request current venue data
            const data = yield this.socket.invoke('getVenueData', this.channel);
            this._onVenueData(data);
        });
    }
    /** Leave a venue. If emit is true, emit cpm: null */
    leaveVenue(emit = true) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.channel === '')
                return;
            const oldChannel = this.channel;
            // reset state
            this.timeTracker.reinitialize();
            this.prevMvtTime = 0;
            this.channel = '';
            // abort all for-await loops on this channel
            this.socket.killChannel(oldChannel);
            yield this.socket.unsubscribe(oldChannel);
            if (emit) {
                this.emit('cpm', null);
            }
        });
    }
    /** log an event, like a user action, to be stored on chronos */
    logEvent(type, value) {
        // console.log('logEvent', type, value);
        this.socket.transmit('logEvent', { type, value });
    }
    /** get info about chronos server */
    getInfo() {
        return __awaiter(this, void 0, void 0, function* () {
            // throttle server calls, so as not to overwhelm server
            const now = Date.now();
            if (now > this.lastInfoRequestTime + 3000) {
                // console.log('get info');
                this.lastInfoRequestTime = now;
                const data = yield this.socket.invoke('getInfo', null);
                this.clientCount = data.clientsCount;
            }
            // but return data we have immediately
            return {
                time: this.clock.getTime(),
                latency: this.clock.stats,
                clients: this.clientCount,
            };
        });
    }
    /** Director ONLY: set the concert, piece, and movement IDs for this venue.
     * This should cause viewers to become active.
     */
    setCPM(concert, piece, movement) {
        if (!this.socket || !this.channel)
            return;
        const data = { channel: this.channel, cpm: { concert, piece, movement } };
        this.socket.transmit('setCPM', data);
    }
    /** Director ONLY: clear the concert, piece, and movement  so that we have no
     * active piece right now. Viewers are expected to leave 'active' mode
     */
    clearCPM() {
        if (!this.socket || !this.channel)
            return;
        const data = { channel: this.channel, cpm: null };
        this.socket.transmit('setCPM', data);
    }
    /** Director ONLY: tell the event logging system that a concert is starting
     * now. This will help identify the logged events belonging to this concert
     */
    logConcertStart(concert, name) {
        // console.log('logConcertStart', concert, name);
        const data = { venue: this.channel, concert, name };
        this.socket.transmit('startConcert', data);
    }
    /** Director ONLY: ask for list of pending concert logs */
    getPendingConcertLog(venueId) {
        return __awaiter(this, void 0, void 0, function* () {
            // console.log('getPendingConcertLog', venueId);
            const channel = `venue.${venueId}`;
            const result = yield this.socket.invoke('getPendingConcertLog', channel);
            console.log('got result', result);
            return result;
        });
    }
    /** Director ONLY: set all the pause times for this movement (fermata times + end time).
     * Must be called once by director when a new movement is set, before it starts.
     */
    setPauseTimes(times) {
        this.timeTracker.setPauseTimes(times);
    }
    /**
     * Director ONLY: Set the movement time right now - usually called by the user
     * tapping a key to set the current movement's time
     * @param time current time in seconds
     * @param jump are we jumping? (ie this is a discontinuity)
     * @param motion after this call, force a pause, unpause, or keep previous state
     */
    setMvtTime(time, jump, motion) {
        this.timeTracker.setTime(time, jump, motion);
        this._emitMvtPos();
    }
    /** Director ONLY: Set the current speed/momentum. Setting to 1.0 resets speed to the
     * default speed
     */
    setSpeed(speed) {
        this.timeTracker.setSlope(speed);
        this._emitMvtPos();
    }
    /** Director ONLY */
    pause() {
        if (this.timeTracker.pause())
            this._emitMvtPos();
    }
    /** Director ONLY */
    unPause() {
        if (this.timeTracker.unPause())
            this._emitMvtPos();
    }
    /** Director ONLY */
    isPlaying() {
        return this.timeTracker.isPlaying();
    }
    /** Director ONLY */
    reset() {
        this.timeTracker.reset();
        this._emitMvtPos();
    }
    /** Director ONLY: will broadcast refresh signal now to all venue subscribers */
    refresh(data) {
        if (!this.channel)
            return;
        this.socket.transmit('refresh', { channel: this.channel, data });
    }
    // venue data coming in from server.
    _onVenueData(data) {
        // console.log(`got venue data`, data);
        // clear time-tracker when cpm changes.
        if (data.cpm !== undefined) {
            this.timeTracker.reinitialize();
            this.prevMvtTime = 0;
            this.emit('cpm', data.cpm);
        }
        // update time-tracker's mvtPosState
        if (data.pos !== undefined) {
            const mvtPos = data.pos || (0, time_tracker_1.initMvtPosState)();
            this.timeTracker.setMvtPosState(mvtPos);
        }
        if (data.refresh !== undefined) {
            this.emit('refresh', data.refresh.data);
        }
    }
    _emitMvtPos() {
        if (!this.channel)
            return;
        this.timeTracker.bumpVersion();
        this.socket.transmit('setPos', {
            channel: this.channel,
            pos: this.timeTracker.state,
        });
    }
}
exports.Chronos = Chronos;
let _uid = null;
/** return a unique ID for this client, attempting to make it persistent across sessions */
const getUID = () => {
    // if we already have the ID, return it.
    if (_uid)
        return _uid;
    // try getting from local storage
    try {
        _uid = localStorage.getItem('clientUID') || null;
    }
    catch (_a) { }
    // return ID if that worked.
    if (_uid)
        return _uid;
    // generate a new ID
    // TODO - or, use uuid library
    _uid = Date.now().toString() + Math.random().toString();
    // and try to save it
    try {
        localStorage.setItem('clientUID', _uid);
    }
    catch (_b) { }
    return _uid;
};
