import * as React from 'react';
import styled, { css } from 'styled-components';

import Phone from './media/phone.svg';
import Bach from './media/bach.svg';
import Mountains from './media/mountains.svg';
import Notes from './media/notes.svg';

const images = [Notes, Bach, Mountains];

const PERIOD = 1.25; // seconds per slide

let timer: number | undefined;

type Location = 'left' | 'center' | 'right';

/**
 * An animated representation of how cues work.
 * A previous implementation used animation frames, but this
 * was found to be excessive in power consumption and needs.
 */
export const Demo: React.FC = () => {
  // A value between 0 -> 2
  const [slide, setSlide] = React.useState(0);

  React.useEffect(() => {
    const callback = () => {
      // We provide setSlide with a function so we can
      // get the current value of slide instead of the initial
      setSlide((slide) => (slide + 1) % images.length);
    };

    timer = window.setInterval(callback, PERIOD * 1000);

    // Our unmount function
    return () => {
      window.clearInterval(timer);
    };
  }, []);

  const count = images.length;
  const slides = images.map((src, i) => {
    let location: Location = 'center';

    if (i < slide) {
      location = 'left';
    } else if (i > slide) {
      location = 'right';
    }

    // Make sure our left and right stack look fifo
    const z = location === 'left' ? i : count - i;

    return <StyledSlide $location={location} src={src} key={i} style={{ zIndex: z }} alt='' />;
  });

  return (
    <StyledDemo>
      <img src={Phone} style={{ zIndex: 10 }} alt='' />
      {slides}
    </StyledDemo>
  );
};

const StyledDemo = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (width: 320px), (height: 320px) {
    transform: scale(0.5);
  }

  @media (height: 320px) {
    transform: scale(0.3);
  }
`;

const StyledSlide = styled.img<{ $location: Location }>`
  position: absolute;
  transition: all 500ms ease;
  transform: translateX(0) scale(1);

  ${(p) =>
    p.$location !== 'center' &&
    css`
      filter: brightness(0.7);
      transform: translateX(${120 * (p.$location === 'left' ? -1 : 1)}%) scale(0.85);
    `}
`;
