import * as Common from 'common';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { ListEntry, ListEntryClasses } from '../../components/list-entry';

import { ChronosDirectedContext, ChronosProgressContext } from '../../lib/contexts';
import { generateEndpoint } from '../../lib/types';
import { Colors } from '../../styles';

interface Props {
  movement: Common.Movement;
}

export const Movement: React.FC<Props> = (props) => {
  const { movement } = props;

  const params = useParams<{
    venue: string;
    concert: string;
    piece: string;
  }>();

  const to = generateEndpoint(params.venue!, params.concert, params.piece, movement.id);

  const movementProgress = React.useContext(ChronosProgressContext).movement;
  const directed = React.useContext(ChronosDirectedContext);

  const hasDirection = directed.concert !== null;
  const active = directed.movement === movement.id;
  const progress = movementProgress[movement.id];

  return (
    <StyledMovementEntry
      to={to}
      title={movement.name}
      subtitle={movement.subtitle}
      progress={progress && active ? progress : undefined}
      $hasDirection={hasDirection}
      $active={active}
    />
  );
};

const StyledMovementEntry = styled(ListEntry)<{
  $hasDirection: boolean;
  $active: boolean;
}>`
  .${ListEntryClasses.Row} {
    padding-left: 66px;
  }

  ${(p) =>
    p.$hasDirection &&
    css`
      .${ListEntryClasses.Titles} {
        color: ${p.$active ? Colors.white : Colors.grayscale045};
      }
    `}
`;
