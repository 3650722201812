"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.timesFromPositions = exports.timesFromPosition = exports.barPathOccurrences = exports.timeFromPosition = exports.calculateRenderedPath = exports.renderPathFromAnnotations = exports.renderPathFromPath = exports.calcBarDuration = exports.barSplitType = exports.noteLenAsQuarterNotes = exports.pathRange = exports.regionsFromPath = void 0;
const types_1 = require("../types");
/** convert path to regions, where each region is [start, end] and end is 1-after the last bar.
 * This will create overlapping regions.
 */
const regionsFromPath = (path) => {
    const jumps = [...path.jumps, { from: path.last, to: 0 }];
    const regions = jumps.map((j, idx) => ({
        start: idx === 0 ? path.first : jumps[idx - 1].to,
        end: j.from + 1,
    }));
    return regions;
};
exports.regionsFromPath = regionsFromPath;
/** convert annotations to regions, where each region is [start, end] and
 * end is 1-after the last bar. This will create overlapping regions.
 */
const regionsFromAnnotations = (annotations) => {
    // create regions
    const regions = [];
    const len = annotations.length;
    let start = len ? annotations[0].barIdx : 0;
    for (let idx = 0; idx < len; idx++) {
        const cur = annotations[idx];
        const prev = annotations[idx - 1];
        if ((prev && cur.barIdx !== prev.barIdx + 1) || idx === len - 1) {
            const end = prev.barIdx + 1;
            regions.push({ start, end });
            start = cur.barIdx;
        }
    }
    return regions;
};
/** Common implementation to create a rendered path from a list of overlapping regions.
 * This will create the actual path and figure out region IDs and repetitions. However,
 * it does not calculate times / durations. Those must be done later.
 */
const renderPathImpl = (regions, splits) => {
    // find all region boundaries, sorted, so that we can make non-overlapping regions
    const bounds = [];
    for (const r of regions) {
        if (bounds.indexOf(r.start) === -1)
            bounds.push(r.start);
        if (bounds.indexOf(r.end) === -1)
            bounds.push(r.end);
    }
    bounds.sort((a, b) => a - b);
    // create non-overlapping regions from the bounds
    const noRegions = [];
    for (const r of regions) {
        let i = bounds.indexOf(r.start);
        const e = bounds.indexOf(r.end);
        for (; i < e; i++) {
            noRegions.push({
                start: bounds[i],
                end: bounds[i + 1],
                tag: `${bounds[i]}:${bounds[i + 1]}`,
            });
        }
    }
    // give each region an ID number based on if it is a repeated section.
    // sections that do not repeat get an ID of 0.
    let nextID = 1;
    const regionData = {};
    for (const r of noRegions) {
        const tag = r.tag;
        if (tag in regionData) {
            if (regionData[tag].id === 0)
                regionData[tag].id = nextID++;
        }
        else {
            regionData[tag] = { id: 0, rep: 0 };
        }
    }
    // finally, construct the output rendered path as an array of RenderedBars
    // with the correct repetition count and regions. time / duration set to 0 for now.
    const output = [];
    // go through the non-overlapping regions array, filling out the sequence of bars that
    // it dictates
    for (const r of noRegions) {
        const rd = regionData[r.tag];
        rd.rep += 1;
        for (let idx = r.start; idx < r.end; ++idx) {
            const sp = (0, exports.barSplitType)(idx, splits);
            const time = 0;
            const duration = 0;
            const tempo = types_1.noTempo;
            output.push({ idx, regionId: rd.id, rep: rd.rep, split: sp, time, duration, tempo });
        }
    }
    return output;
};
/**
 * computes the bounds of this path - the earliest and latest bars seen
 * in this path. These are not necessarily path.first and path.last because of jumps
 * @param path the path
 * @returns [first, last] range as bar indexes. last is inclusive.
 */
const pathRange = (path) => {
    let { first, last } = path;
    // expand first and last if jumps dictate it
    for (const j of path.jumps) {
        first = Math.min(j.to, first);
        last = Math.max(j.from, last);
    }
    return [first, last];
};
exports.pathRange = pathRange;
/**
 * returns the number of quarter notes in the given noteLen ('whole' => 4, 'd_quarter' => 1.5, etc.)
 */
const noteLenAsQuarterNotes = (noteLen) => {
    switch (noteLen) {
        case 'whole':
            return 4;
        case 'd_half':
            return 3;
        case 'half':
            return 2;
        case 'd_quarter':
            return 1.5;
        case 'quarter':
            return 1;
        case 'd_eighth':
            return 0.75;
        case 'eighth':
            return 0.5;
        case 'sixteenth':
            return 0.25;
        default:
            return 0;
    }
};
exports.noteLenAsQuarterNotes = noteLenAsQuarterNotes;
/** If the barIdx is part of a split-bar pair, then return the fraction of time spent
 * in that bar. Otherwise, return 1 (meaning that barIdx is a complete bar)
 */
const barSplitFactor = (barIdx, splits) => {
    for (let si = 0; si < splits.length; si++) {
        const split = splits[si];
        if (split.barIdx === barIdx)
            return split.ratio;
        if (split.barIdx + 1 === barIdx)
            return 1 - split.ratio;
    }
    return 1.0;
};
/** compute the split bar type for this bar */
const barSplitType = (barIdx, splits) => {
    for (let si = 0; si < splits.length; si++) {
        const split = splits[si];
        if (split.barIdx === barIdx)
            return 'first';
        if (split.barIdx + 1 === barIdx)
            return 'second';
    }
    return null;
};
exports.barSplitType = barSplitType;
/**
 * Calculate the duration of a bar (in seconds) given its TempoData
 * @param tempo the TempoData, including time signature and BPM info
 * @returns the duration (in seconds) of the bar
 */
const calcBarDuration = (tempo) => {
    const { num, denom } = tempo.timeSig;
    const { noteLen, bpm } = tempo.tempo;
    const qNotePerBar = (num / denom) * 4; // quarter notes in a bar
    const qNotePerSec = ((0, exports.noteLenAsQuarterNotes)(noteLen) * bpm) / 60; // quarter notes per second
    return qNotePerBar / qNotePerSec; // seconds per bar
};
exports.calcBarDuration = calcBarDuration;
/**
 * A path's tempos are defined only to include places where tempos (ie, time signatures or BPMs) change.
 * This function returns the correct tempo at every bar of the path, accumulating the changes from beginning
 * to end.
 * @param path the path
 * @returns an array of TempoData, indexed by bar, each one guaranteed to be valid (non-null) and correct.
 * This array is sparse, not starting at 0, since a path's range typically does not start at 0.
 */
const getPathTempos = (path) => {
    // default tempo is 4/4 at quarter=120
    let curTempo = {
        timeSig: { num: 4, denom: 4 },
        tempo: { noteLen: 'quarter', bpm: 120 },
    };
    // the range of bars we care about
    const [first, last] = (0, exports.pathRange)(path);
    // go through all bars of the path and fill in the tempo data
    const output = [];
    for (let idx = first; idx <= last; idx++) {
        // update to current tempo?
        const newTempo = path.tempos.find((t) => t.barIdx === idx);
        if (newTempo) {
            const timeSig = (0, types_1.isNullTimeSig)(newTempo.timeSig) ? curTempo.timeSig : newTempo.timeSig;
            const tempo = (0, types_1.isNullBPM)(newTempo.tempo) ? curTempo.tempo : newTempo.tempo;
            curTempo = { timeSig, tempo };
        }
        output[idx] = curTempo;
    }
    return output;
};
/**
 * From the relatively compact Path structure, create a listing (array) of
 * "rendered bars", aka "the rendered path" - a list of bars actually traversed in this movement.
 * The path describes the motion through the piece (ie, first bar, last bar, jumps), while
 * the rendered path is the realization of that path description. It includes
 * time information per bar (derived from tempos) and repetition info.
 * @param path the path data
 * @returns an array of RenderedBars, aka, the "rendered path"
 */
const renderPathFromPath = (path, splits) => {
    // create rendered path using common implementation
    const regions = (0, exports.regionsFromPath)(path);
    const renderedPath = renderPathImpl(regions, splits);
    // now for each bar, add tempo, duration, and time data:
    const pathTempos = getPathTempos(path);
    // accumulated time throughout the rendered Path
    let time = 0;
    // go through the rendered path and fill out tempo, time, & duration info
    for (const rb of renderedPath) {
        const tempo = pathTempos[rb.idx];
        rb.tempo = tempo;
        rb.duration = (0, exports.calcBarDuration)(tempo) * barSplitFactor(rb.idx, splits);
        rb.time = time;
        time += rb.duration;
    }
    return renderedPath;
};
exports.renderPathFromPath = renderPathFromPath;
/**
 * Convert annotations data into renderedPath data, including regions and repetitions.
 * @param annotations array of annotations
 * @returns array of RenderedBars, the "rendered path"
 */
const renderPathFromAnnotations = (annotations) => {
    // create rendered path using common implementation
    const regions = regionsFromAnnotations(annotations);
    const renderedPath = renderPathImpl(regions, []);
    // go through the rendered path and fill out time / duration data
    console.assert(annotations.length === 1 + renderedPath.length);
    for (let rbi = 0; rbi < renderedPath.length; rbi++) {
        const rb = renderedPath[rbi];
        rb.time = annotations[rbi].time;
        rb.duration = annotations[rbi + 1].time - rb.time;
    }
    return renderedPath;
};
exports.renderPathFromAnnotations = renderPathFromAnnotations;
/**
 * Given a path & SplitBar list, or segment annotations, calculate the rendered path
 * giving priority to segment annotations.
 * @param path
 * @param splits
 * @param annotations
 * @returns
 */
const calculateRenderedPath = (path, splits, annotations) => {
    if (annotations && annotations.length) {
        return (0, exports.renderPathFromAnnotations)(annotations);
    }
    else if (path) {
        return (0, exports.renderPathFromPath)(path, splits || []);
    }
    else {
        return undefined;
    }
};
exports.calculateRenderedPath = calculateRenderedPath;
/**
 * Given a position and a rendered bar, return the time in seconds at which the position occurs
 * @param position the bar based position
 * @param renderedBar the bar from the rendered path
 * @returns a time in seconds
 */
const timeFromPosition = (position, renderedBar) => {
    return renderedBar.time + position.pct * renderedBar.duration;
};
exports.timeFromPosition = timeFromPosition;
/**
 * Return all occurrences in the path for a given barIdx
 * @param barIdx the bar to search for
 * @param renderedPath the path
 * @returns the occurrences in the path at which the barIdx is found
 */
const barPathOccurrences = (barIdx, renderedPath) => {
    return renderedPath.filter((p) => p.idx === barIdx);
};
exports.barPathOccurrences = barPathOccurrences;
/**
 * Given a position and a full rendered path, return the times the position applies to
 * accounting for repetition settings.
 * @param position the bar based position with repetition
 * @param renderedPath the rendered path
 * @returns a list of times in seconds
 */
const timesFromPosition = (position, renderedPath) => {
    const occurrences = (0, exports.barPathOccurrences)(position.barIdx, renderedPath);
    if (position.repetition === 0) {
        return occurrences.map((renderedBar) => (0, exports.timeFromPosition)(position, renderedBar));
    }
    const renderedBar = occurrences[position.repetition - 1];
    if (renderedBar) {
        return [(0, exports.timeFromPosition)(position, renderedBar)];
    }
    return [];
};
exports.timesFromPosition = timesFromPosition;
/**
 * Given a list of positions and a rendered path, return an ordered list of all the times
 * indicated by those positions along the renderedPath, taking into account how positions are repeated.
 * @param positions the bar-based positions
 * @param renderedPath the rendered path
 * @returns a list of times in seconds
 */
const timesFromPositions = (positions, renderedPath) => {
    // the list of times
    const output = [];
    // This is horribly inefficient O(PxR), with P,R = lengths of positions and renderedPath.
    // But it seems to be fast enough (runs in less than 1ms)
    positions.forEach((pos) => {
        const times = (0, exports.timesFromPosition)(pos, renderedPath);
        output.push(...times);
    });
    output.sort((a, b) => a - b);
    return output;
};
exports.timesFromPositions = timesFromPositions;
