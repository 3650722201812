"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.processConcert = exports.processPiece = void 0;
const path_1 = require("./path");
/**
 * Finds all img tags and puts them inside a div with a hard-coded className
 */
const wrapImages = (document) => {
    Array.from(document.getElementsByTagName('img')).forEach((item) => {
        const wrapper = document.createElement('div');
        wrapper.className = 'img-wrapper';
        if (item.parentNode) {
            item.parentNode.insertBefore(wrapper, item);
        }
        wrapper.appendChild(item);
    });
};
/**
 * Removes the inner text content of any definition spans so that we may use
 * our custom tooltip component without the original label interfering.
 * @param document
 */
const stripDefinitionContent = (document) => {
    Array.from(document.getElementsByTagName('span'))
        .filter((tag) => tag.getAttribute('data-type') === 'definition')
        .forEach((tag) => {
        // clear children
        tag.removeChild(tag.firstChild);
    });
};
/**
 * Content is processed by stripping inline definitions and wrapping the images
 * @param content the cue content to process
 * @returns the processed content
 */
const processCueContent = (content) => {
    // Parse the HTML content
    const document = new DOMParser().parseFromString(content, 'text/html');
    wrapImages(document);
    stripDefinitionContent(document);
    if (!document.body) {
        throw new Error('No document body when performing cue replacements!');
    }
    return document.body.innerHTML;
};
/**
 * Process Cues array into a more useful format:
 * - cue images wrapped and inline definitions stripped
 * - time values calculated from cue position data (which "renders" the cues into a timeline)
 * @param cues
 * @param renderedPath
 * @returns the new array of cues
 */
const processCues = (cues, renderedPath) => {
    const output = [];
    for (let i = 0; i < cues.length; i++) {
        const cue = cues[i];
        const content = processCueContent(cue.content);
        // a Cue position needs to be converted into an array of times, due to possible
        // repetitions/jumps built into the rendered path. So we could see multiple copies
        // of the same cue at different times, or no cues if the position does not
        // exist within the rendered path.
        const position = cue.position;
        const times = (0, path_1.timesFromPosition)(position, renderedPath);
        for (const time of times) {
            output.push({ id: cue.id, time, content, position });
        }
    }
    // Cues must be time sorted.
    output.sort((a, b) => a.time - b.time);
    return output;
};
/**
 * Process a piece so that all of its cues are transformed to have more useful data
 * that what the server returns. See processCues()
 * @param piece the piece data to process
 * @returns the processed piece
 */
function processPiece(piece) {
    return Object.assign(Object.assign({}, piece), { movements: piece.movements.map((m) => {
            var _a;
            // we need the rendered path for this movement in order to process cue positions
            // if rendered path comes back undefined, we have to make the cues list empty.
            const anns = (_a = m.segment) === null || _a === void 0 ? void 0 : _a.annotations;
            const renderedPath = (0, path_1.calculateRenderedPath)(m.path, piece.splitbars, anns);
            return Object.assign(Object.assign({}, m), { stories: m.stories.map((s) => (Object.assign(Object.assign({}, s), { cues: renderedPath ? processCues(s.cues, renderedPath) : [] }))) });
        }) });
}
exports.processPiece = processPiece;
/**
 * Process a concert to transform underlying data into a more useable format
 * @param concert the concert data to process
 * @returns the processed concert
 */
function processConcert(concert) {
    return Object.assign(Object.assign({}, concert), { program: concert.program.map((e) => (Object.assign(Object.assign({}, e), { piece: processPiece(e.piece) }))) });
}
exports.processConcert = processConcert;
