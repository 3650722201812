import * as React from 'react';
import { Cue } from 'common';

export const useCueTime = (position: number, cues: Cue[], duration: number) => {
  // Get the cues surrounding the current position
  const [first, last] = React.useMemo(() => {
    const f = cues.filter((c) => c.time <= position).at(-1);
    const l = cues.find((c) => c.time > position);
    return [f, l];
  }, [cues, position]);

  // Calculate the normalized progress between the cues, or end of the movement
  return React.useMemo(() => {
    if (!first) {
      return {
        durationBetweenCues: null,
        timeSinceStartOfCue: null,
        remainingTime: null,
        normalizedProgress: null,
      };
    }

    // The time, in seconds, between the current and next cue or end of story
    const durationBetweenCues = (last?.time || duration) - first.time;
    // The time since the first cue, in seconds
    const timeSinceStartOfCue = position - first.time;
    // Remaining time till next cue, or end of the movement
    const remainingTime = (last?.time || duration) - position;
    // The normalized progress between the cues, or end of the movement
    const normalizedProgress = timeSinceStartOfCue / durationBetweenCues;

    return { durationBetweenCues, timeSinceStartOfCue, remainingTime, normalizedProgress };
  }, [first, last, position, duration]);
};
