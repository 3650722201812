import * as React from 'react';
import styled from 'styled-components';

import { Colors, Typography } from '../../styles';

enum Classes {
  Title = 'entry-titles-title',
  Subtitle = 'entry-titles-subtitle',
}

interface TitlesProps {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  className?: string;
}

export const Titles: React.FC<TitlesProps> = (props: TitlesProps) => {
  const { title, subtitle, className } = props;
  return (
    <StyledTitles className={className}>
      <div className={Classes.Title}>{title}</div>
      {props.subtitle && <div className={Classes.Subtitle}>{subtitle}</div>}
    </StyledTitles>
  );
};
Titles.displayName = 'Titles';

export const StyledTitles = styled.div`
  flex: 1 1 auto;
  padding: 12px 0;
  overflow: hidden;
  color: ${Colors.white};

  ${Typography.montserrat}

  * {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .${Classes.Title} {
    font-size: 16px;
  }

  .${Classes.Subtitle} {
    font-weight: lighter;
    font-size: 14px;
    margin-top: 4px;
    opacity: 0.8;
  }
`;
