import * as React from 'react';

import { BackToRealtimeContainer } from '../../components/back-to-realtime';
import { Survey } from '../../components/survey';
import {
  ChronosCallbacks,
  ChronosDirectedContext,
  chronosDirectedDefaults,
  ChronosDirectedType,
  useAppContext,
} from '../../lib/contexts';
import { AutoNavigator } from './auto-navigator';
import { useSurveyCleanse, getChronos } from '../../lib/hooks';
import { CPM } from 'chronos-client';
import { Outlet, useParams } from 'react-router-dom';

/**
 * The root level view of a particular venue; responsible for rendering all
 * venue-specific paths beneath it
 */
export const Venue: React.FC = () => {
  const { venue } = useParams<{ venue: string }>();
  const { setAppState } = useAppContext();

  const chronos = getChronos();

  /**
   * Keep track of the chronos venue data in this component; it will be pass
   */
  const [directed, setDirected] = React.useState<ChronosDirectedType>({
    ...chronosDirectedDefaults,
  });

  React.useEffect(() => {
    const onCPM = (data: CPM | null) => {
      const directed = {
        concert: data?.concert || null,
        piece: data?.piece || null,
        movement: data?.movement || null,
        connected: true,
      };

      // When a concert ends, go back to directed so we're ready for the next time
      if (!directed.concert) {
        setAppState((s) => ({ ...s, browseMode: 'directed' }));
      }

      setDirected(directed);
    };

    chronos.on('cpm', onCPM);

    chronos.joinVenue(venue!);

    return () => {
      chronos.off('cpm', onCPM);
    };
  }, [venue, chronos, setAppState]);

  React.useEffect(() => {
    return () => {
      chronos.leaveVenue();
    };
    // eslint-disable-next-line
  }, []);

  const callbacks = React.useMemo(
    () => ({
      logEvent: (event: string, data: any) => chronos.logEvent(event, data),
    }),
    [chronos],
  );

  // Cleans up the primed survey if necessary
  useSurveyCleanse();

  return (
    <ChronosCallbacks.Provider value={callbacks}>
      <ChronosDirectedContext.Provider value={directed}>
        <BackToRealtimeContainer />
        <Survey />
        <Outlet />
        <AutoNavigator />
      </ChronosDirectedContext.Provider>
    </ChronosCallbacks.Provider>
  );
};
Venue.displayName = 'Venue';
