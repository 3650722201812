import * as React from 'react';
import { useParams } from 'react-router';

import { ChronosDirectedContext, ChronosDirectedType } from './contexts';
import * as Common from 'common';
import { Chronos } from 'chronos-client';

const CHRONOS_HOST = process.env.REACT_APP_CHRONOS_HOST || '';
const CHRONOS_PORT = process.env.REACT_APP_CHRONOS_PORT || '';

export const PRIMED_SURVEY_KEY = 'primedSurvey';

/**
 * Primes a concert's survey if the concert is directed and has a survey
 *
 * Priming keeps the survey in local storage so that we may load it at the end
 * of the concert, or next time the client opens.
 */
export const useSurveyPrimer = (concertData: Common.Concert | undefined) => {
  const { venue } = useParams<{ venue: string }>();

  // The directed concert id
  const { concert } = React.useContext(ChronosDirectedContext);

  React.useEffect(() => {
    const survey = concertData?.survey;
    if (concert && concertData?.id === concert && survey) {
      try {
        const surveyData = JSON.stringify({
          venueId: venue,
          survey: survey,
        });

        if (localStorage.getItem(PRIMED_SURVEY_KEY) === surveyData) {
          return;
        }
        localStorage.setItem(PRIMED_SURVEY_KEY, surveyData);
      } catch (error) {}
    }
  }, [concert, concertData, venue]);
};

/**
 * Retrieves the primed survey from local storage
 * NOTE: THIS DOESN'T TRIGGER RE-RENDERS WHEN LOCAL-STORAGE CHANGES
 * @returns the primed survey and venue id, if it exists
 */
export const usePrimedSurvey = () => {
  const raw = localStorage.getItem(PRIMED_SURVEY_KEY);
  return raw ? (JSON.parse(raw) as { venueId: string; survey: Common.Survey }) : undefined;
};

/**
 * Removes the primed survey if the currently navigated venue does not match the
 * its venue id.
 */
export const useSurveyCleanse = () => {
  const { venue } = useParams<{ venue: string }>();
  const primed = usePrimedSurvey();

  React.useEffect(() => {
    if (!primed || primed.venueId === venue) {
      return;
    }
    localStorage.removeItem(PRIMED_SURVEY_KEY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [venue]);
};

let chronosInstance: Chronos | undefined;

/**
 * Instantiates a singleton instance of chronos
 * @returns the chronos instance
 */
export const getChronos = () => {
  if (!chronosInstance) {
    chronosInstance = new Chronos(CHRONOS_HOST, parseInt(CHRONOS_PORT));
  }

  return chronosInstance;
};

/** A helper for polling and using the chronos position */
export const useChronosPosition = (directed: ChronosDirectedType, pollingInterval = 200) => {
  const chronos = getChronos();

  const [position, setPosition] = React.useState<number>(0);
  React.useEffect(() => {
    setPosition(0);
    const polling = window.setInterval(() => {
      setPosition(chronos.getMvtTime());
    }, pollingInterval);

    return () => {
      window.clearInterval(polling);
    };
    // eslint-disable-next-line
  }, [directed.movement]);

  return position;
};
