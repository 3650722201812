"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClockSync = void 0;
/** Filter for clock sync. Provides an estimate of the server time based
 * on a number of samples added, and their age.
 */
class ClockEstimatorFilter {
    constructor() {
        this.samples = [];
        this.loc_ave = 0;
        this.ref_ave = 0;
        this.age = 60; // dump samples older than this
        this.num_samples = 4; // average up to this many samples to arrive at loc_ave and ref_ave
    }
    numSamples() {
        return this.samples.length;
    }
    // and a new time sample, which consists of a local time, a server time,
    // and the latency associated with this sample.
    insert(local, ref, latency) {
        // check if incoming data is very different from what we expect:
        if (this.samples.length > 0) {
            const delta = Math.abs(this.ref_ave - this.loc_ave - (ref - local));
            // console.log('delta', delta, 'num samples', this.samples.length);
            // if we are off by more than 10 seconds, something went wrong - so start over.
            if (delta > 10)
                this.samples = [];
        }
        this.samples.push({ local, ref, latency });
        // sort by latency
        this.samples.sort((a, b) => a.latency - b.latency);
        // remove samples older than this.age seconds
        const thresh = local - this.age;
        this.samples = this.samples.filter((x) => x.local > thresh);
        // finally, calc params based on the N most promising samples:
        const num = Math.min(this.num_samples, this.samples.length);
        if (num) {
            let loc_sum = 0;
            let ref_sum = 0;
            for (let i = 0; i < num; i++) {
                const s = this.samples[i];
                loc_sum += s.local;
                ref_sum += s.ref;
            }
            this.loc_ave = loc_sum / num;
            this.ref_ave = ref_sum / num;
        }
    }
    // given a local time, return the estimated server time
    get(local) {
        // if we don't have any samples, return 0
        if (this.ref_ave === 0) {
            return 0;
        }
        else {
            return this.ref_ave + local - this.loc_ave;
        }
    }
}
/** Client-server clock synchronization.
 * Pings a server to request server time (using supplied onPing function).
 * The response (pong) should be provided via addClockPong.
 * After a few ping-pongs have happened, getTime() will return the estimated
 * server time.
 */
class ClockSync {
    constructor(ping) {
        this.onPing = ping;
        this.nextPingTime = 0;
        this.filter = new ClockEstimatorFilter();
        // used only for data analysis
        this.latencyIdx = 0;
        this.latencySamples = [];
        // latency statistics
        this.stats = { interval: 0, min: 0, max: 0, ave: 0 };
        // get started, polling every 200 ms
        this.pollID = setInterval(this._poll.bind(this), 200);
    }
    stop() {
        clearInterval(this.pollID);
    }
    /** call this when the server returns a timestamp */
    addClockPong(localPing, refTime) {
        // refTime is the time of the server
        // localPing is the local time when the request was made.
        // localTime is the estimate of localTime at the moment when refTime was set
        // by the server. This assumes travel time to and fro are equal.
        const localPong = this.getLocalTime();
        const travelDur = localPong - localPing;
        const localTime = (localPong + localPing) / 2;
        this._process(localTime, refTime, travelDur);
    }
    // return the estimated server time. Returns 0 when not yet determined
    getTime() {
        return this.filter.get(this.getLocalTime());
    }
    // get local time in seconds
    getLocalTime() {
        if (typeof performance !== 'undefined') {
            return 0.001 * performance.now();
        }
        else {
            return 0.001 * Date.now();
        }
    }
    _poll() {
        const localNow = this.getLocalTime();
        if (localNow > this.nextPingTime) {
            // console.log('ping: ' + localNow.toFixed(3));
            this.onPing(this.getLocalTime());
            this.nextPingTime = localNow + 3.0; // largest amount of time to wait before trying another ping.
        }
    }
    _process(localTime, refTime, travelDur) {
        // insert new data into filter
        this.filter.insert(localTime, refTime, travelDur);
        const numSamples = this.filter.numSamples();
        // record travelDur / latency data for later analysis
        this.latencySamples[this.latencyIdx] = { localTime, travelDur };
        this.latencyIdx = (this.latencyIdx + 1) % 5;
        this._calcLatencyStats();
        // generate next ping-pong:
        var pingDelay;
        if (numSamples > 20)
            pingDelay = 2.0;
        else if (numSamples > 10)
            pingDelay = 1.0;
        else
            pingDelay = 0.5;
        var delta = pingDelay + Math.random() * pingDelay * 0.1;
        this.nextPingTime = this.getLocalTime() + delta;
    }
    // returns min/max/ave latency times for the past N samples representing 'interval' duration of time.
    _calcLatencyStats() {
        let tMin = 1000000000;
        let tMax = 0;
        let lMin = 1000000000;
        let lMax = 0;
        let lSum = 0;
        for (let i = 0; i < this.latencySamples.length; i++) {
            const t = this.latencySamples[i].localTime;
            const l = this.latencySamples[i].travelDur;
            tMin = Math.min(tMin, t);
            tMax = Math.max(tMax, t);
            lMin = Math.min(lMin, l);
            lMax = Math.max(lMax, l);
            lSum += l;
        }
        this.stats = {
            interval: tMax - tMin,
            min: lMin,
            max: lMax,
            ave: lSum / this.latencySamples.length,
        };
    }
}
exports.ClockSync = ClockSync;
