import * as Common from 'common';
import * as React from 'react';
import styled from 'styled-components';

import { Colors, Typography } from '../../../../styles';
import { ScheduleList } from './ScheduleList';

const Copy = {
  Past: 'Past Concerts',
};

interface ScheduleProps {
  schedule: { past: Common.ConcertSummary[]; current: Common.ConcertSummary[] };
}

/**
 * Renders the list of concert summaries for the current venue
 */
export const Schedule: React.FC<ScheduleProps> = (props: ScheduleProps) => {
  const { schedule } = props;

  const hasPast = schedule.past.length > 0;
  const hasCurrent = schedule.current.length > 0;

  return (
    <>
      <ScheduleList concerts={schedule.current} />
      {hasPast && <StyledSectionHeader>{Copy.Past}</StyledSectionHeader>}
      <ScheduleList concerts={schedule.past} dim={hasCurrent} />
    </>
  );
};
Schedule.displayName = 'Schedule';

const StyledSectionHeader = styled.div`
  margin: 12px 24px 0 24px;
  padding: 12px 0 12px 0;

  ${Typography.montserrat}
  border-bottom: 1px solid ${Colors.colorListSeparator};
`;
