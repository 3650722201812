import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { Definition } from './Definition';

interface Props {
  /** TODO: This deck is problematic, as it is apparently mutated instead of replaced */
  deck: HTMLDivElement | null;
  onDefinitionShown: (definition: { word: string; content: string }) => void;
}

export const Definitions: React.FC<Props> = (props) => {
  // Get the cue deck element
  if (!props.deck) {
    console.warn('[Definitions]: Deck is null...');
    return null;
  }

  const definitions = Array.from(props.deck.getElementsByTagName('span'))
    .filter((tag) => tag.getAttribute('data-type') === 'definition')
    .map((tag) => {
      // Extract the word and its definition
      const word = unescape(tag.getAttribute('data-word') || '');
      const content = unescape(tag.getAttribute('data-content') || '');

      // This is necessary or else the definition shifts the text content around
      tag.setAttribute('style', 'display:contents');

      if (!content || !word) {
        throw new Error(
          `Missing definition attribute! word: "${word}" content: "${content}"`,
        );
      }

      // Create the definition component...
      const hoverDef = (
        <Definition
          definition={{ word, content }}
          onShown={props.onDefinitionShown}
        />
      );

      // Create the portal...
      return ReactDOM.createPortal(hoverDef, tag);
    });

  return <React.Fragment>{definitions}</React.Fragment>;
};
