import * as React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { Colors } from '../../styles';

interface Props {
  to: string;
  onClick: () => void;
  children: React.ReactNode;
}

enum Classes {
  Active = 'active',
}

export const Item: React.FC<Props> = (props) => {
  return (
    <StyledNavLink
      to={props.to}
      className={({ isActive }) => (isActive ? Classes.Active : '')}
      onClick={props.onClick}
      end
    >
      <>{props.children}</>
    </StyledNavLink>
  );
};

const StyledNavLink = styled(NavLink)`
  height: 0px;
  padding: 0;

  transition: all 250ms;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  color: ${Colors.colorNavEntries};
  font-size: 15px;
  text-decoration: none;

  &:hover {
    color: unset;
  }

  &.${Classes.Active} {
    color: ${Colors.orange};
  }
`;
