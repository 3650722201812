import * as React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { Titles } from './Titles';
import { Colors } from '../../styles';

interface Props {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  /** The path to go to when clicked */
  to: string;
  /** Optional overriding status */
  className?: string;
  /** The progress of this entry; only rendered if provided */
  progress?: number;
  /**
   * Optional additional components to render aside the titles; typically used
   * for a drop down icon / button
   */
  fixtures?: React.ReactNode;
  /**
   * Optional additional components to render beneath the titles, but above the
   * progress bar; this is used to show a piece description for example
   */
  widgets?: React.ReactNode;
}

export enum ListEntryClasses {
  Row = 'list-entry-row',
  Titles = 'list-entry-titles',
}

/**
 * This component renders a row in the piece and movements list. It features
 * exposed classes (ListEntryClasses) for easily modifying the appearance in derived
 * components.
 * @returns
 */
export const ListEntry: React.FC<React.PropsWithChildren<Props>> = (
  props: React.PropsWithChildren<Props>,
) => {
  const { className, to, progress } = props;
  return (
    <StyledListEntry className={className}>
      <NavLink to={to} className={className}>
        <>
          <div className={ListEntryClasses.Row}>
            <Titles
              title={props.title}
              subtitle={props.subtitle}
              className={ListEntryClasses.Titles}
            />
            {props.fixtures}
          </div>
          {props.widgets}
          {progress ? <StyledProgress value={progress} /> : null}
        </>
      </NavLink>
      {props.children}
    </StyledListEntry>
  );
};
ListEntry.displayName = 'ListEntry';

const StyledListEntry = styled.div`
  transition: all 250ms;
  opacity: 1;
  background-color: ${Colors.palette1};

  .${ListEntryClasses.Row} {
    display: flex;
    padding: 0 24px 0 44px;
    align-items: center;
    min-height: 64px;
  }

  a {
    display: block;
  }
`;

const StyledProgress = styled.progress`
  -webkit-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  height: 2px;

  &::-webkit-progress-bar {
    background-color: transparent;
  }

  &::-webkit-progress-value {
    background-color: ${Colors.orange};
  }
`;
