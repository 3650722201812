import * as React from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { Clients } from 'common';

import { Loader } from '../../../../loader/Loader';
import { Entry } from './entry';
import { useAcknowledgeTutorial } from '../../../hooks';

const COPY = {
  LOADING: 'Finding Venues…',
  NONE: 'No Venues - Try Refreshing',
};

/**
 * Renders the list of venues to choose from for the tutorial
 * - If not done loading, shows the loading bars
 * - If no venues after loading, shows an error message
 */
export const Picker: React.FC = () => {
  const venuesQuery = useQuery('venues', ({ signal }) => Clients.getVenues(signal));

  const complete = useAcknowledgeTutorial();

  if (venuesQuery.status !== 'success') {
    return <Loader>{COPY.LOADING}</Loader>;
  }

  if (venuesQuery.data.length === 0) {
    return <div>{COPY.NONE}</div>;
  }

  const entries = venuesQuery.data.map((v) => {
    return <Entry id={v.id} name={v.name} image={v.logoImage} key={v.id} onClick={complete} />;
  });

  return <StyledPicker>{entries}</StyledPicker>;
};

const StyledPicker = styled.div`
  flex: 0 1 auto;
  width: 100%;
  max-width: 600px;
  overflow-y: scroll;

  > :not(:last-child) {
    margin-bottom: 10px;
  }
`;
