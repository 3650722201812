import * as React from 'react';
import { Slide } from '../../../generic/deck/slide';

import Logo from '../../../../img/cc_round.png';

const COPY = {
  BODY: `ConcertCue streams program notes to your phone during a live concert.`,
  ALT: 'ConcertCue Logo',
};

/** Renders the initial welcome slide in the tutorial */
export const Welcome: React.FC = () => {
  return (
    <Slide>
      <img src={Logo} alt={COPY.ALT} />
      <p>{COPY.BODY}</p>
    </Slide>
  );
};
Welcome.displayName = 'Welcome';
