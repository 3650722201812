import * as React from 'react';
import SwipeableViews from 'react-swipeable-views';
import styled from 'styled-components';

import { Finish } from './finish';
import * as Slides from './slides';
import { ChronosDirectedContext, ConcertContext } from '../../lib/contexts';
import { Colors } from '../../styles';
import { Dots } from '../generic/deck/dots';
import { TutorialPopoverContainer } from '../generic/deck/popover';
import { Skip } from '../generic/deck/skip';
import { useAcknowledgeConcertTutorial, useIsConcertTutorialVisible } from './hooks';

/**
 * Order of persistent tutorial slides determined by
 * this array
 */
const persistentSlides: React.FC[] = [Slides.FontZoomer, Slides.BackToRealtime];

enum Classes {
  Slides = 'slides',
}

// const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

/**
 * Deck for showing feature tutorial slides
 *
 * Differentiates between persistent and toggle-able
 * features; Persistent features are shown last so
 * that the user always sees any new features they
 * may have not seen in prior visits.
 */
export const ConcertTutorialDeck: React.FC = () => {
  const [index, setIndex] = React.useState(0);

  const concert = React.useContext(ConcertContext);

  // add optional features
  const features = concert?.properties.features;
  const featureSlides: React.FC[] = [];
  if (features?.localizedStories) featureSlides.push(Slides.LocalizedStories);
  if (features?.popupDefinitions) featureSlides.push(Slides.PopupDefinitions);

  // Add iOS specific slides, if necessary
  // remove this for now. iOS minimizeUI doesn't always work this way.
  const platformSlides: React.FC[] = [];
  // const isIOS = iosPlatforms.indexOf(window.navigator.platform) !== -1;
  // if (isIOS) {
  //   platformSlides.push(Slides.MinimizeUI);
  // }

  // Combine our feature and persistent slides
  const slides = [Slides.Features, ...featureSlides, ...persistentSlides, ...platformSlides];

  const onClick = useAcknowledgeConcertTutorial(concert?.id);

  const children = slides.map((S, i) => <S key={i} />);

  // Finish button on last slide
  let finish = null;
  if (index >= slides.length - 1) {
    finish = <Finish onClick={onClick} />;
  }

  return (
    <StyledConcertTutorial>
      <SwipeableViews
        index={index}
        onChangeIndex={setIndex}
        className={Classes.Slides}
        containerStyle={{ height: '100%' }}
        enableMouseEvents={true}
      >
        {children}
      </SwipeableViews>
      {finish}
      <Dots count={slides.length} index={index} goTo={(i) => setIndex(i)} />
      <Skip onClick={onClick} />
    </StyledConcertTutorial>
  );
};
ConcertTutorialDeck.displayName = 'ConcertTutorialDeck';

export const ConcertTutorialPopover: React.FC = () => {
  const currentConcertId = React.useContext(ChronosDirectedContext).concert;
  const isVisible = useIsConcertTutorialVisible(currentConcertId);

  return (
    <TutorialPopoverContainer isVisible={isVisible}>
      <ConcertTutorialDeck />
    </TutorialPopoverContainer>
  );
};

const StyledConcertTutorial = styled.div`
  flex: 1 1 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${Colors.grayscale01};

  .${Classes.Slides} {
    flex: 1 1 0;
    flex-basis: 0;
    margin-top: 18px;
  }
`;
