import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Colors } from '../../styles';
import { generateEndpoint } from '../../lib/types';
import * as Common from 'common';

interface Props {
  venue: Common.Venue;
}

enum Classes {
  Item = 'item',
  Logo = 'logo',
  Name = 'name',
  Description = 'description',
}

export const Item: React.FC<Props> = (props) => {
  const { venue } = props;
  const to = generateEndpoint(venue.id);

  return (
    <StyledItem to={to}>
      <div className={Classes.Item}>
        <div className={Classes.Logo}>
          <img src={venue.logoImage} alt={venue.name} />
        </div>
        <div>
          <span className={Classes.Name}>{venue.name}</span>
          <p className={Classes.Description}>{venue.description}</p>
        </div>
      </div>
    </StyledItem>
  );
};

const StyledItem = styled(Link)`
  text-decoration: none;
  color: white;

  &:hover {
    color: unset;
  }

  .${Classes.Item} {
    display: flex;
    flex-direction: row;

    border-top: 1px solid ${Colors.colorListSeparator};
    padding: 16px 0;

    .${Classes.Logo} {
      width: 110px;
      overflow: hidden;
      padding-right: 24px;

      flex: 0 0 auto;

      img {
        max-width: 100%;
      }
    }

    .${Classes.Name} {
      font-size: 18px;
      font-weight: normal;
      display: block;
      padding-bottom: 4px;
    }

    .${Classes.Description} {
      font-size: 14px;
      font-weight: lighter;
    }
  }
`;
