import * as Common from 'common';
import * as React from 'react';

import { Movement } from './Movement';

interface MovementsProps {
  movements: Common.Movement[];
}

export const Movements: React.FC<MovementsProps> = (props: MovementsProps) => {
  const { movements } = props;
  const list = movements.map((m) => {
    return <Movement key={m.id} movement={m} />;
  });
  return <>{list}</>;
};
