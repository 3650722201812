import * as React from 'react';
import styled from 'styled-components';

import { Colors, Typography } from '../../../../../styles';

const COPY = {
  TAKE_IT: 'Take Survey',
  SKIP_IT: 'No thanks...',
};

export interface Props {
  /** The survey's link for the user */
  link: string;
  /** The user accepts */
  takeIt: () => void;
  /** The user declines */
  skipIt: () => void;
}

/** Renders buttons for the survey prompt */
export const SurveyButtons: React.FC<Props> = (props) => {
  return (
    <StyledSurveyButtons>
      <StyledLinkButton
        target='_blank'
        rel='noopener noreferrer'
        href={props.link}
        onClick={props.takeIt}
      >
        <span>{COPY.TAKE_IT}</span>
      </StyledLinkButton>
      <StyledSkipButton onClick={props.skipIt}>{COPY.SKIP_IT}</StyledSkipButton>
    </StyledSurveyButtons>
  );
};
SurveyButtons.displayName = 'SurveyButtons';

const StyledSurveyButtons = styled.div`
  ${Typography.montserrat}
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
`;

const StyledLinkButton = styled.a`
  font-size: 20px;
  color: ${Colors.white};
  background-color: ${Colors.grayscale03};

  border-bottom: 1px solid ${Colors.palette4};

  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 4px;
  width: 100%;
  max-width: 24rem;
`;

const StyledSkipButton = styled.div`
  display: block;
  margin-top: 8px;
  width: 100%;
  color: ${Colors.grayscale045};
  font-size: 16px;
  padding: 8px;
  padding-bottom: 0;
  cursor: pointer;
  user-select: none;
`;
