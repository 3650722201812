import * as Common from 'common';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ChronosDirectedContext } from '../../../../lib/contexts';
import { Colors, Typography } from '../../../../styles';

/** Define how the concert's date string looks for the user */
const DATE_OPTIONS: Intl.DateTimeFormatOptions = {
  weekday: 'short',
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

enum Classes {
  Name = 'name',
  Date = 'date',
}

interface ScheduleConcertProps {
  active: boolean;
  concert: Common.ConcertSummary;
  dim?: boolean;
}

const ScheduleConcert: React.FC<ScheduleConcertProps> = (props: ScheduleConcertProps) => {
  const { concert, active, dim } = props;
  const formattedDate = new Date(concert.nextPerformance).toLocaleString(
    'en-US',
    DATE_OPTIONS,
  );

  const kind = active ? 'active' : dim ? 'dimmed' : 'plain';

  return (
    <StyledScheduleConcert to={`concerts/${concert.id}`} $kind={kind}>
      <span className={Classes.Name}>{concert.name}</span>
      <span className={Classes.Date}>{formattedDate}</span>
    </StyledScheduleConcert>
  );
};

const StyledScheduleConcert = styled(Link)<{
  $kind: 'plain' | 'dimmed' | 'active';
}>`
  display: block;
  background-color: ${Colors.palette1};
  padding: 16px 0;
  margin: 0 24px;

  color: ${Colors.white};

  .${Classes.Name}, .${Classes.Date} {
    display: block;
    ${Typography.montserrat}
  }

  .${Classes.Name} {
    color: ${(p) => {
      switch (p.$kind) {
        case 'active':
          return Colors.orange;
        case 'dimmed':
          return Colors.listDimmedTitleColor;
        case 'plain':
        default:
          return Colors.listTitleColor;
      }
    }};
    font-size: 16px;
  }

  .${Classes.Date} {
    color: ${(p) => {
      switch (p.$kind) {
        case 'active':
          return Colors.orange;
        case 'dimmed':
          return Colors.listDimmedSubtitleColor;
        case 'plain':
        default:
          return Colors.listSubtitleColor;
      }
    }};
    font-weight: lighter;

    font-size: 14px;
    line-height: 20px;
  }

  border-bottom: 1px solid ${Colors.colorListSeparator};
`;

interface ScheduleListProps {
  concerts: Common.ConcertSummary[];
  dim?: boolean;
}

/**
 * Renders a list of concert summaries
 * @param props
 */
export const ScheduleList: React.FC<ScheduleListProps> = (props: ScheduleListProps) => {
  // Get the active concert so we can highlight the correct entry
  const { concert } = React.useContext(ChronosDirectedContext);

  return (
    <StyledSummary>
      {props.concerts.map((c) => (
        <ScheduleConcert concert={c} key={c.id} dim={props.dim} active={c.id === concert} />
      ))}
    </StyledSummary>
  );
};

const StyledSummary = styled.div``;
