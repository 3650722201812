import * as React from 'react';
import styled from 'styled-components';

import close from '../../../img/ui/close.png';
import info from '../../../img/ui/info.png';

export interface Props {
  expanded: boolean;
  toggle: (e: React.PointerEvent<HTMLDivElement>) => void;
}

export const InformationToggle: React.FunctionComponent<Props> = (
  props: Props,
) => (
  <StyledInformationToggle onClick={props.toggle}>
    <img src={props.expanded ? close : info} alt='' />
  </StyledInformationToggle>
);

const StyledInformationToggle = styled.div`
  width: 20px;
  height: 100%;
  text-align: right;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 16px;

  img {
    height: 20px;
    width: auto;
  }
`;
