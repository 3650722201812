import * as React from 'react';

import { CueDeck } from '../cue-deck';
import { ChronosCallbacks, ConcertContext } from '../../lib/contexts';
import * as Common from 'common';

interface Props {
  isSynchronized: boolean;
  position: number;
  zoom: number;
  story: Common.Story;
  lock?: boolean;
  tapCallback?: () => void;
  onUserChange?: (position: number) => void; // callback when the user swipes forward or back a slide
}

export const CueDeckContainer = (props: Props) => {
  const concert = React.useContext(ConcertContext);

  const { logEvent } = React.useContext(ChronosCallbacks);

  const shouldCrossfade =
    concert?.properties.presentation?.crossfadeCues || false;

  const onDefinitionShown = (definition: { word: string; content: string }) => {
    logEvent('showDefinition', definition);
  };

  return (
    <CueDeck
      {...props}
      shouldCrossfade={shouldCrossfade}
      onDefinitionShown={onDefinitionShown}
    />
  );
};
