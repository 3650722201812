import * as React from 'react';
import styled from 'styled-components';

import { Colors } from '../../styles';

const LINK_TIMEOUT = 2000;

const COPY = {
  PRIMARY: 'Redirecting...',
  LINK: 'If no redirection occurs, navigate to ',
};

interface Props {
  /** The URL to redirect to */
  to: string;
}

/**
 * Redirects the user to the specified URL using window.location
 * so that external URLs can be used. If no redirect occurs after
 * the default timeout, then a clickable message with the
 * destination link is shown.
 */
export const ExternalRedirect: React.FC<Props> = (props) => {
  const [showLink, setShowLink] = React.useState(false);

  React.useEffect(() => {
    const timer = window.setTimeout(() => {
      setShowLink(true);
    }, LINK_TIMEOUT);

    window.location.replace(props.to);

    return () => {
      window.clearTimeout(timer);
    };
    // We purposely want this to only run once
    // eslint-disable-next-line
  }, []);

  const link = showLink ? (
    <StyledLink>
      {COPY.LINK} <a href={props.to}>{props.to}</a>
    </StyledLink>
  ) : null;

  return (
    <StyledExternalRedirect>
      <StyledPrimaryMessage>{COPY.PRIMARY}</StyledPrimaryMessage>
      {link}
    </StyledExternalRedirect>
  );
};
ExternalRedirect.displayName = 'ExternalRedirect';

const StyledExternalRedirect = styled.section`
  text-align: center;
  padding: 16px;
`;

const StyledPrimaryMessage = styled.div`
  font-size: 1.8em;
`;

const StyledLink = styled.div`
  font-size: 1em;
  color: ${Colors.grayscale04};
  padding: 8px;
`;
