import * as Common from 'common';
import * as React from 'react';

export * from './App';

/**
 * Provides the concert object to all components beneath the highest level by
 * path where the concert is specified;
 *
 * For example, when the path is http://concertcue.com/venue/1/concert/2, the
 * object in this context should be the concert identified by ID "2"
 */
export const ConcertContext = React.createContext<Common.Concert | undefined>(undefined);
ConcertContext.displayName = 'ConcertContext';

/**
 * Provides the piece object to all components beneath the highest level by path
 * where the piece is specified;
 *
 * For example, when the path is http://concertcue.com/venue/1/concert/2/piece/3
 * the object in this context should be the piece identified by ID "3"
 */
export const PieceContext = React.createContext<Common.Piece | undefined>(undefined);
PieceContext.displayName = 'PieceContext';

/**
 * Provides the movement object to all components beneath the highest level by
 * path where the movement is specified;
 *
 * For example, when the path is http://concertcue.com/venue/1/concert/2/piece/3/movement/4
 * the object in this context should be the movement identified by ID "4"
 */
export const MovementContext = React.createContext<Common.Movement | undefined>(undefined);
MovementContext.displayName = 'MovementContext';

/**
 * Provides the story object to all components beneath the highest level by path
 * where the story is specified;
 *
 * For example, when the path is http://concertcue.com/venue/1/concert/2/piece/3/movement/4/story/1
 * the object in this context should be the the story of index 1 in movement 3's story array
 */
export const StoryContext = React.createContext<Common.Story | undefined>(undefined);
StoryContext.displayName = 'StoryContext';

/**
 * Context providing callbacks to our chronos instance
 */
export const ChronosCallbacks = React.createContext<{
  logEvent: (event: string, data: any) => void;
}>({ logEvent: () => {} });
ChronosCallbacks.displayName = 'ChronosCallbacksContext';

export interface ChronosDirectedType {
  connected: boolean;
  concert: string | null;
  piece: string | null;
  movement: string | null;
}

export const chronosDirectedDefaults: ChronosDirectedType = {
  connected: false,
  concert: null,
  piece: null,
  movement: null,
};

export const ChronosDirectedContext =
  React.createContext<ChronosDirectedType>(chronosDirectedDefaults);
ChronosDirectedContext.displayName = 'ChronosDirectedContext';

export interface ChronosProgressType {
  /** The normalized progress through the entire concert [0, 1] */
  concert: number;
  /** The normalized progress through each piece in the concert, referenced by piece ID */
  piece: Record<string, number>;
  /** The normalized progress through each movement in the concert, referenced by movement ID */
  movement: Record<string, number>;
}

export const chronosProgressDefaults = { concert: 0, piece: {}, movement: {} };

export const ChronosProgressContext =
  React.createContext<ChronosProgressType>(chronosProgressDefaults);
ChronosProgressContext.displayName = 'ChronosProgressContext';
