import * as React from 'react';
import styled from 'styled-components';
import { Colors } from '../../styles';

interface Props {
  expanded: boolean;
  className?: string;
}

/**
 * A specially styled, collapsable section for our piece list
 * @param props
 * @returns
 */
export const PieceDescription: React.FC<React.PropsWithChildren<Props>> = (
  props: React.PropsWithChildren<Props>,
) => {
  const { children, expanded, className } = props;

  if (!children) {
    return null;
  }

  return (
    <StyledPieceDescription $expanded={expanded} className={className}>
      {children}
    </StyledPieceDescription>
  );
};
PieceDescription.displayName = 'PieceDescription';

const StyledPieceDescription = styled.section<{ $expanded: boolean }>`
  height: ${(p) => (p.$expanded ? 'auto' : 0)};
  opacity: ${(p) => (p.$expanded ? 0.8 : 0)};
  padding: ${(p) => (p.$expanded ? '0 24px 24px 44px' : '0 24px')};
  overflow: hidden;
  transition: all 250ms;
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 14px;
  line-height: 20px;
  color: ${Colors.white};

  a {
    display: unset;
  }
`;
