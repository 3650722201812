"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calcTime = exports.TimeTracker = exports.initMvtPosState = void 0;
const d3_array_mini_1 = require("./lib/d3-array-mini");
const initMvtPosState = () => {
    return {
        srvTime: 0,
        mvtTime: 0,
        slope: 1,
        pauseTime: 0,
        version: 0,
    };
};
exports.initMvtPosState = initMvtPosState;
/** Class that adjusts/updates the MvtPosState to be correct based on actions
 * such as setTime(), pause(), and unpause(). Should only be used by the ChronosDirector.
 */
class TimeTracker {
    constructor(clock) {
        // return the next pauseTime after the given time. If time is too big (which should
        // not happen, see assert), return the last pauseTime, which should be the end time
        // of the movement.
        this._nextPauseTime = (time) => {
            let i = (0, d3_array_mini_1.bisectRight)(this.pauseTimes, time);
            console.assert(i < this.pauseTimes.length, `no pause time after ${time}`);
            i = Math.min(i, this.pauseTimes.length - 1);
            // as a safety check, return 0 if this.pauseTimes[i] is undefined/
            // this shouldn't happen, but there is still a bug somewhere that
            // causes pauseTimes to be empty.
            return this.pauseTimes[i] || 0;
        };
        this.state = (0, exports.initMvtPosState)();
        this.clock = clock; // for getting the server time
        this.pauseTimes = [0];
        this.didJump = false;
    }
    /** Set the list of times where mvtTime should automatically pause.
     * This should include a final number representing the end time of the movement */
    setPauseTimes(times) {
        console.assert(times.length && times[0] !== 0, 'Invalid pause Times!');
        this.pauseTimes = times;
    }
    /** update current state from incoming network event */
    setMvtPosState(state) {
        // to avoid old network data, only update if incoming version is
        // not old.
        if (state.version === 0 || state.version >= this.state.version) {
            this.state = state;
        }
        else {
            console.log(`not updating: ${state.version} < ${this.state.version}`);
        }
    }
    /** increase the state's version # (to be done before sending out to the server) */
    bumpVersion() {
        this.state.version += 1;
    }
    /** reset back to fresh state */
    reinitialize() {
        this.state = (0, exports.initMvtPosState)();
        this.pauseTimes = [0];
        this.didJump = false;
    }
    /**
     * Set the current movement time.
     * @param mvtTime the movement time right now.
     * @param jump if true, this is a "large jump" so avoid slope calculations
     * @param motion after this call, force a pause, unpause, or keep previous state
     */
    setTime(mvtTime, jump, motion) {
        console.assert(this.pauseTimes.length && this.pauseTimes[0] !== 0, 'Pause times were not set'); // should have been set by now
        const srvTime = this.clock.getTime();
        // if there is no valid server time, best we can do is pause at this point.
        if (srvTime === 0) {
            this.state.srvTime = 0;
            this.state.mvtTime = mvtTime;
            this.state.slope = 1;
            this.state.pauseTime = mvtTime;
            return;
        }
        // to avoid skipping over a pauseTime, check if setTime might do that.
        // if next pause time is in between now and desired mvtTime, set mvtTime
        // to just prior to nextPause time so we don't skip it.
        if (motion === 'unpause' && !jump) {
            const now = (0, exports.calcTime)(this.state, srvTime);
            const nextPause = this._nextPauseTime(now);
            if (nextPause <= mvtTime) {
                mvtTime = nextPause - 0.0001;
            }
        }
        // find out if we are paused before changing internal variables.
        const isPaused = !this.isPlaying();
        // new slope is created from a point on the line d seconds ago
        const d = 5.0;
        // that point is (srv1, mvt1)
        const srv1 = this.state.srvTime - d;
        const mvt1 = this.state.mvtTime + this.state.slope * (srv1 - this.state.srvTime);
        // new slope is dy/dx based on current point (srvTime, mvtTime) and a point d seconds ago.
        const slope = (mvtTime - mvt1) / (srvTime - srv1);
        // are we currently paused at a pauseTime (ie, fermata)? Unpausing from a fermata
        // should not adjust slope.
        const pausedAtFermata = isPaused && this.pauseTimes.indexOf(this.state.pauseTime) !== -1;
        // should we use this slope or not? Don't use a new slope under these conditions:
        // first tap; server clock not ready; jump; paused at fermata; slope too big; slope too small
        const badSlope = this.state.srvTime === 0 ||
            srvTime === 0 ||
            srvTime === this.state.srvTime ||
            jump ||
            this.didJump ||
            pausedAtFermata ||
            slope > 3 ||
            slope < 0.33;
        // Remember that this was a jump, so we avoid slope calc next time too.
        this.didJump = jump;
        // set current values
        this.state.mvtTime = mvtTime;
        this.state.srvTime = srvTime;
        if (!badSlope)
            this.state.slope = slope;
        // if we want to pause as a result of this call
        if (motion === 'pause' || (isPaused && motion === 'keep')) {
            // stay paused, at the new given time
            this.state.pauseTime = mvtTime;
        }
        else {
            // otherwise, pause time is set to a future value
            this.state.pauseTime = this._nextPauseTime(mvtTime);
        }
    }
    /** return false if was already paused. Otherwise, pause, and return true */
    pause() {
        if (!this.isPlaying())
            return false;
        // pause at the current time
        const nowTime = (0, exports.calcTime)(this.state, this.clock.getTime());
        this.state.pauseTime = nowTime;
        return true;
    }
    /** return false was already playing. Otherwise, unpause, and return true */
    unPause() {
        if (this.isPlaying())
            return false;
        // unpause by setting time to now (ie the paused time) and going from there.
        this.setTime(this.state.pauseTime, false, 'unpause');
        return true;
    }
    /** Force the current slope to a value. For example, setting to 1.0 resets
     * the slope to the default speed
     */
    setSlope(slope) {
        const srvTime = this.clock.getTime();
        const mvtTime = (0, exports.calcTime)(this.state, srvTime);
        this.state.mvtTime = mvtTime;
        this.state.srvTime = srvTime;
        this.state.slope = slope;
    }
    /** reset back to beginning of movement & paused */
    reset() {
        this.state = (0, exports.initMvtPosState)();
    }
    isPlaying() {
        // we are paused if current time has reached pauseTime.
        // otherwise, pauseTime is in the future, and we are playing.
        const nowTime = (0, exports.calcTime)(this.state, this.clock.getTime());
        const playing = nowTime < this.state.pauseTime;
        return playing;
    }
}
exports.TimeTracker = TimeTracker;
/**
 * Calculate the movement time given serverTime and mvtPosState.
 */
const calcTime = (mvtPos, serverTime) => {
    // movement time calculation: last known time + linear extrapolation based
    // on slope and elapsed server time.
    let mvtTime = mvtPos.mvtTime;
    if (serverTime)
        mvtTime += mvtPos.slope * (serverTime - mvtPos.srvTime);
    // don't go past pauseTime or go negative (shouldn't happen)
    mvtTime = Math.max(0, Math.min(mvtTime, mvtPos.pauseTime));
    return mvtTime;
};
exports.calcTime = calcTime;
