import * as React from 'react';
import styled from 'styled-components';

import { Popover } from '../popover/Popover';

interface Props {
  credits: string;
  close: () => void;
}

const COPY = {
  CREDITS: 'Credits',
};

export const Credits: React.FC<Props> = (props) => {
  return (
    <Popover title={COPY.CREDITS} close={props.close}>
      <StyledCredits dangerouslySetInnerHTML={{ __html: props.credits }} />
    </Popover>
  );
};
Credits.displayName = 'Credits';

const StyledCredits = styled.div`
  padding: 16px;
`;
