import * as Common from 'common';
import * as React from 'react';
import styled from 'styled-components';

import {
  SectionHeader,
  Classes as SectionHeaderClasses,
} from '../../components/section-header/SectionHeader';
import { StoryTabs } from '../../components/story-viewer/story-tabs/StoryTabs';
import { StoryViewer } from '../../components/story-viewer/StoryViewer';
import {
  ChronosCallbacks,
  ChronosDirectedContext,
  ChronosProgressContext,
  ConcertContext,
  MovementContext,
  PieceContext,
  StoryContext,
  useAppContext,
} from '../../lib/contexts';
import { generateEndpoint } from '../../lib/types';

import { Mixins } from '../../styles';
import { useNavigate, useParams } from 'react-router-dom';

/**
 * Renders the stories view, which includes CueDeck, timeline, and
 * tabs to switch between the available stories
 *
 * Note that this view expects to be nested within a Concert and Piece context.
 * @param props
 */
export const Stories: React.FC = () => {
  const params = useParams<{
    venue: string;
    concert: string;
    piece: string;
    movement: string;
    storyIndex: string;
  }>();
  const navigate = useNavigate();

  const concert = React.useContext(ConcertContext);
  const piece = React.useContext(PieceContext);
  const progress = React.useContext(ChronosProgressContext);
  const directed = React.useContext(ChronosDirectedContext);

  const isSingleMovement = piece?.movements.length === 1;

  // Extract the movement specified by this routes params
  const movement = React.useMemo(
    () => piece?.movements.find((m) => m.id === params.movement),
    [piece, params.movement],
  );

  // pick the current story
  const stories = movement?.stories || [];
  const selected = stories[Math.min(stories.length, parseInt(params.storyIndex!)) || 0];

  const position = (progress.movement[params.movement!] || 0) * (movement?.duration || 0);
  const { appState, setAppState } = useAppContext();
  const isInConcert = !!directed.concert;
  const isFullscreen = concert?.properties.presentation?.fullscreenStory || false;

  const { logEvent } = React.useContext(ChronosCallbacks);

  const override = React.useCallback(() => {
    if (isInConcert && appState.browseMode === 'directed') {
      setAppState((s) => ({ ...s, browseMode: 'independent' }));
      logEvent('setRealtime', false);
    }
  }, [isInConcert, appState, setAppState, logEvent]);

  /** Construct a back button link */
  const backRoute = generateEndpoint(
    params.venue!,
    params.concert,
    isSingleMovement ? undefined : params.piece,
  );

  /**
   * This effect redirects the user back to the first story if the story
   * requested by their URL does not exist.
   */
  React.useEffect(() => {
    // Skip empty / undefined strings
    if (!params.storyIndex) {
      return;
    }

    // Lets get the index out of the string
    let index = parseInt(params.storyIndex);
    if (isNaN(index)) {
      return;
    }

    // Go back to the movement root if the index is out of bounds
    if (index >= stories.length) {
      const path = generateEndpoint(
        params.venue!,
        params.concert,
        params.piece,
        params.movement,
      );
      navigate(path);
    }
    // eslint-disable-next-line
  }, [params.storyIndex, params.movement]);

  /** When the user selects a different story */
  const onSelect = (id: Common.Story['id']) => {
    const index = stories.findIndex((s) => s.id === id);
    logEvent('setStory', index);
    const path = generateEndpoint(
      params.venue!,
      params.concert,
      params.piece,
      params.movement,
      index.toString(),
    );
    navigate(path);
  };

  if (!movement) {
    return null;
  }

  return (
    <MovementContext.Provider value={movement}>
      <StoryContext.Provider value={selected}>
        <StyledStories>
          <StyledHeader
            backTo={backRoute}
            details={isSingleMovement ? piece.description : movement.description}
          >
            <StyledLabel>{isSingleMovement ? piece.name : movement.name}</StyledLabel>
            <StoryTabs stories={stories} select={onSelect} selected={selected?.id} />
          </StyledHeader>
          <StoryViewer
            isSynchronized={appState.browseMode === 'directed' && isInConcert}
            position={position}
            override={override}
            isFullscreen={isFullscreen}
            key={selected?.id}
          />
        </StyledStories>
      </StoryContext.Provider>
    </MovementContext.Provider>
  );
};
Stories.displayName = 'Stories';

const StyledStories = styled.div`
  ${Mixins.view}
`;

const StyledHeader = styled(SectionHeader)`
  .${SectionHeaderClasses.Label} {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

const StyledLabel = styled.span`
  flex: 0 1 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
