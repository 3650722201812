export const black = '#000000';
export const gray = '#1f1f1f';
export const orange = '#e9973f';

export const white = '#ffffff';

export const colorNavBackground = '#0c0b0b';
export const colorNavMenu = '#999999';
export const colorNavEntries = white;

export const colorListSeparator = '#303131';
export const colorListAlternate = '#303131';

export const palette0 = '#000000';
export const palette1 = '#1f1f1f';
export const palette2 = '#08747c';
export const palette3 = '#00a9b5';
export const palette4 = '#cedd0b';
export const palette5 = '#f7931e';
export const palette6 = '#ea3c1f';
export const palette7 = '#ffffff';

export const grayscale01 = '#2c2c2c';
export const grayscale02 = '#313131';
export const grayscale03 = '#3e3e3e';
export const grayscale04 = '#606060';
export const grayscale045 = '#808080';
export const grayscale05 = '#dddddd';

export const listTitleColor = white;
export const listSubtitleColor = '#c0c0c0';

export const listDimmedTitleColor = '#808080';
export const listDimmedSubtitleColor = '#606060';

export const colorTimelineDefault = '#4d4d4d';

export const backdropDimmer = 'rgba(0, 0, 0, 0.7)';
