import * as React from 'react';
import styled from 'styled-components';

const COPY = {
  LABEL: 'Font',
};

export interface Props {
  /** The current zoom level */
  zoom: number;
  /** Whether to enable zoom button */
  canIncrease: boolean;
  /** Whether to enable unzoom button */
  canDecrease: boolean;
  /** zoom in callback */
  increase: () => void;
  /** zoom out callback */
  decrease: () => void;
  /** Whether to make the tool visible */
  show: boolean;
}

export const SizeAdjuster: React.FC<Props> = (props) => {
  const decrease = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    props.decrease();
  };

  const increase = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    props.increase();
  };

  return (
    <StyledSizeAdjuster $show={props.show}>
      <StyledButton onClick={decrease} $disabled={!props.canDecrease}>
        &#8722;
      </StyledButton>
      <StyledLabel>{COPY.LABEL}</StyledLabel>
      <StyledButton onClick={increase} $disabled={!props.canIncrease}>
        &#43;
      </StyledButton>
    </StyledSizeAdjuster>
  );
};
SizeAdjuster.displayName = 'SizeAdjuster';

const borderColor = 'rgba(255, 255, 255, 0.2)';

const StyledSizeAdjuster = styled.div<{ $show: boolean }>`
  height: 36px;
  width: 178px;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: ${(p) => (p.$show ? 1 : 0)};
  pointer-events: ${(p) => (p.$show ? 'all' : 'none')};
  transition: opacity 250ms linear;
  border-radius: 8px;
  overflow: hidden;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  user-select: none;

  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  z-index: 9000;
`;

const StyledButton = styled.div<{ $disabled: boolean }>`
  width: 64px;
  font-size: 24px;
  font-weight: bold;
  opacity: ${(p) => (p.$disabled ? 0.4 : 1)};
  pointer-events: ${(p) => (p.$disabled ? 'none' : 'all')};
  transition: opacity 0.25s;
  text-align: center;

  &:active {
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.4);
  }
`;

const StyledLabel = styled.div`
  padding: 0 0.5rem;
  border-right: 1px solid ${borderColor};
  border-left: 1px solid ${borderColor};
  height: 36px;
  width: 50px;
  display: flex;
  align-items: center;
`;
