"use strict";
/**
 * Defines endpoints for use with ConcertCue Server.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.user = exports.tokens = void 0;
exports.tokens = {
    access: () => `/api/token/`,
    refresh: () => `/api/token/refresh/`,
    withLink: (token) => `/api/auth/login-link/${token}/`,
};
/** Retrieve user information */
exports.user = {
    /** Information for the currently auth-ed user */
    me: () => '/api/me/',
    changePassword: () => '/api/me/change_password/',
};
