import { Cue } from 'common';
import * as React from 'react';
import { useCueTime } from './hooks';

interface Props {
  cues: Cue[];
  position: number;
  duration: number;
}

/**
 * Experimental: Indicates the progress through the
 * current cue until we get to the next cue
 */
export const CueProgress: React.FC<Props> = ({ position, cues, duration }) => {
  const { normalizedProgress } = useCueTime(position, cues, duration);

  return (
    <progress
      value={normalizedProgress || 0}
      className='w-full h-0.5 rounded-none [&::-webkit-progress-bar]:bg-transparent [&::-webkit-progress-value]:bg-cyan-500'
    />
  );
};
CueProgress.displayName = 'CueProgress';
