import * as React from 'react';
import styled from 'styled-components';

import { Dot } from './Dot';
import { Colors } from '../../../../styles';

const COPY = {
  /** Instruction provided on all but last slide */
  INSTRUCTION: 'Swipe to continue',
};

export interface Props {
  /** How many dots to render */
  count: number;
  /** 0-based dot to highlight */
  index: number;
  /** Callback when the user taps the nth dot */
  goTo?: (index: number) => void;
}

/**
 * Renders the indication / navigation dots and instruction for a deck of slides
 * @param props
 */
export const Dots: React.FC<Props> = (props) => {
  const dots = [];

  for (let i = 0; i < props.count; i++) {
    const active = i === props.index;
    const onClick = () => {
      if (props.goTo) {
        props.goTo(i);
      }
    };

    const dot = <Dot active={active} onClick={onClick} key={i} />;
    dots.push(dot);
  }

  return (
    <StyledDotsContainer>
      <StyledInstruction $hidden={props.index >= props.count - 1}>
        {COPY.INSTRUCTION}
      </StyledInstruction>
      <StyledDots>{dots}</StyledDots>
    </StyledDotsContainer>
  );
};
Dots.displayName = 'Dots';

const StyledDotsContainer = styled.div`
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;

  box-shadow: 0px 0px 4px 0px ${Colors.grayscale01};
  z-index: 1;

  .instruction {
  }
`;

const StyledDots = styled.div`
  display: flex;
  flex-direction: row;
  margin: 12px 0;

  > * {
    margin: 0 4px;
  }
`;

const StyledInstruction = styled.div<{ $hidden: boolean }>`
  color: ${Colors.grayscale04};
  font-size: 14px;
  top: 20px;
  opacity: ${(p) => (p.$hidden ? 0 : 1)};
  transition: opacity 250ms;
`;
